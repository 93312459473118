import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '1.1rem',
    },
  },
  palette: {
    primary: {
      main: '#10A8BF',
    },
    secondary: {
      main: '#0A6172',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
        },
      },
    },
  },
});

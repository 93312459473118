import { JSX } from 'react';

import { Grid } from '@mui/material';
import { AppBar } from 'components/appBar';
import { AppBarUiProps } from 'components/appBar/interfaces/AppBarUiProps';
import { AppDrawer } from 'components/appDrawer';
import { AppDrawerProps } from '../appDrawer/interfaces/AppDrawerProps';

export const LayoutUI = ({
  children,
  appBarProps,
  appDrawerProps,
}: {
  children: JSX.Element;
  appBarProps: AppBarUiProps;
  appDrawerProps: AppDrawerProps;
}) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      sx={{
        backgroundColor: '#f5f5f5',
        width: 'calc(100vw - 10px)',
        margin: 0,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: '56px',
        }}
      >
        <AppBar {...appBarProps} />
      </Grid>

      <Grid
        item
        sx={{
          width: appDrawerProps.open ? '240px' : '64px',
        }}
      >
        <AppDrawer {...appDrawerProps} />
      </Grid>

      <Grid
        item
        sx={{
          marginLeft: appDrawerProps.open ? '232px' : '57px',
          height: 'calc(100vh - 72px)',
          width: appDrawerProps.open
            ? 'calc(100vw - 240px)'
            : 'calc(100vw - 64px)',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Grid
          container
          justifyContent={'center'}
          sx={{
            height: '100%',
          }}
        >
          <Grid
            sx={{
              width: '100%',
              maxWidth: '1200px',
              padding: '15px',
              backgroundColor: '#ffff',
              overflow: 'auto',
            }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

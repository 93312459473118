import { NurseRequestBody } from 'hooks/api/Nurse/interfaces/nurse.request';
import { NurseFormInputs } from '../interfaces/NurseFormInputs';

export const makeRequestBody = ({
  confirmPassword,
  email,
  name,
  password,
  phone,
}: NurseFormInputs): NurseRequestBody => {
  return {
    confirmPassword,
    email,
    name,
    password,
    phone,
  };
};

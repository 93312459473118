import { useApi } from 'hooks/api';
import { PaginatedRequest } from '../interfaces/paginated.request';
import { DoctorResponse } from './interfaces/doctor.response';
import { PaginatedResponse } from '../interfaces/paginated.response';
import { DoctorSearchResponse } from './interfaces/doctorSearch.response';

export const useDoctorsService = () => {
  const { get, put, patch, del } = useApi();

  const getDoctors = async (
    params: PaginatedRequest<DoctorResponse>
  ): Promise<PaginatedResponse<DoctorResponse>> => {
    const { data } = await get<PaginatedResponse<DoctorResponse>>(
      '/doctor/find-all',
      { params }
    );
    return data;
  };

  const searchDoctors = async (
    searchTerm?: string
  ): Promise<PaginatedResponse<DoctorSearchResponse>> => {
    const { data } = await get<PaginatedResponse<DoctorSearchResponse>>(
      searchTerm ? '/doctor/search?value=' + searchTerm : '/doctor/search/'
    );
    return data;
  };

  const searchDoctorsCampaign = async (
    searchTerm?: string
  ): Promise<PaginatedResponse<DoctorSearchResponse>> => {
    const { data } = await get<PaginatedResponse<DoctorSearchResponse>>(
      searchTerm
        ? '/doctor/search-campaign?value=' + searchTerm
        : '/doctor/search-campaign/'
    );
    return data;
  };

  const approveDoctor = async (id: number): Promise<any> => {
    const { data } = await patch<any>('/doctor/restore/' + id);
    return data;
  };

  const rejectDoctor = async (id: number): Promise<any> => {
    const { data } = await del<any>('/doctor/delete/' + id);
    return data;
  };

  return {
    getDoctors,
    approveDoctor,
    rejectDoctor,
    searchDoctors,
    searchDoctorsCampaign,
  };
};

import yup from 'utils/yup';
import { transformStringToNumberOrUndefined } from 'utils/stringUtils';

export const schema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')])
    .required(),
});

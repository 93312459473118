import { Button, Grid, Typography } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid';
import { PaginatedTable } from 'components/paginatedTable';

import PlusIcon from '@mui/icons-material/Add';

export const PlansUI = ({
  columns,
  rows,
  handlePaginationStateChange,
  handlerSortModelChange,
  paginationState,
  totalRows,
  isLoading,
  handleNavigateToForm,
}: {
  columns: GridColDef[];
  rows: GridRowsProp;
  handlePaginationStateChange: ({
    page,
    pageSize,
  }: GridPaginationModel) => void;
  handlerSortModelChange: (newModel: GridSortModel) => void;
  paginationState: { page: number; pageSize: number };
  totalRows: number;
  isLoading: boolean;
  handleNavigateToForm: (id?: string) => void;
}) => {
  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <Typography
              variant='h4'
              component={'h1'}
              color='primary'
              fontWeight='bold'
            >
              Planos
            </Typography>
          </Grid>
          <Grid item>
            <Button
              startIcon={<PlusIcon />}
              variant='contained'
              fullWidth
              sx={{
                fontWeight: 'bold',
                color: 'white',
              }}
              onClick={() => handleNavigateToForm()}
            >
              Novo Plano
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PaginatedTable
          {...{
            columns,
            rows,
            handlePaginationStateChange,
            handlerSortModelChange,
            paginationState,
            totalRows,
            isLoading,
          }}
        />
      </Grid>
    </Grid>
  );
};

import { useApi } from 'hooks/api';

import { PaginatedRequest } from '../interfaces/paginated.request';
import { PartnerRequest } from './interfaces/partner.request';
import { PartnerResponse } from './interfaces/partner.response';
import { PaginatedResponse } from '../interfaces/paginated.response';

export const usePartnerService = () => {
  const { post, put, get, del } = useApi();

  const postPartner = async ({ body }: PartnerRequest) => {
    return post('/partner/create', body);
  };

  const putUpdatePartner = async ({ body }: PartnerRequest, id: number) => {
    return put(`/partner/update/${id}`, body);
  };

  const getPartner = async (id: number) => {
    const { data } = await get<PartnerResponse>(`/partner/find/${id}`);
    return data;
  };

  const getPartners = async (
    params: PaginatedRequest<PartnerResponse>
  ): Promise<PaginatedResponse<PartnerResponse>> => {
    const { data } = await get<PaginatedResponse<PartnerResponse>>(
      '/partner/find',
      { params }
    );
    return data;
  };

  const deletePartner = async (id: number) => {
    return del(`/partner/delete/${id}`);
  };

  return {
    postPartner,
    putUpdatePartner,
    getPartner,
    getPartners,
    deletePartner,
  };
};

import { convertToNumberOrUndefined } from 'utils/stringUtils';
import { RecoverPasswordInputs } from '../interfaces/RecoverPasswordInputs';
import { RecoverPasswordRequestBody } from 'hooks/api/Users/interfaces/recoverPassword.request';

export const makeRequestBody = (
  { password }: RecoverPasswordInputs,
  recoveryToken: string
): RecoverPasswordRequestBody => {
  return {
    newPassword: password,
    recoveryToken,
  };
};

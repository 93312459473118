import { Grid, Typography, useTheme } from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InputText } from 'components/input';
import { LoadingButton } from 'components/loadingButton';

import { NumberInput } from 'components/numberInput';
import { LoadingPage } from '../../components/loadingPage';
import { useInputsStyle } from '../../hooks/inputsStyle';
import { PlanFormInputs } from './interfaces/PlanFormInputs';
import { Dropdown } from 'components/select';

export const PlanFormUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  isLoadingGetData,
  id,
  control,
  options,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<PlanFormInputs>;
  errors: FieldErrors<PlanFormInputs>;
  isLoading: boolean;
  id: string | undefined;
  isLoadingGetData: boolean;
  control: Control<PlanFormInputs>;
  options: {
    value: string;
    label: string;
  }[];
}) => {
  const theme = useTheme();

  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });

  return (
    <LoadingPage isLoading={isLoadingGetData}>
      <Grid {...container}>
        <Grid item xs={12}>
          <Grid item xs={12} mb={3}>
            <Typography variant='h4' component='h1' color='secondary'>
              {id === 'new' ? 'Criar Plano' : `Alterar Plano #${id}`}
            </Typography>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-name'
                  label='Nome'
                  validationMessage={errors.name?.message}
                  register={register('name')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...inputsSections}>
                <Grid {...normalInput}>
                  <Dropdown<PlanFormInputs>
                    id='dropdown-type'
                    control={control}
                    label='Tipo'
                    name='frequency'
                    disabled={isLoading}
                    key='type'
                    validationMessage={errors.name?.message}
                    options={options}
                  />
                </Grid>
              </Grid>
              <Grid {...normalInput}>
                <NumberInput
                  id='input-price'
                  label='Preço'
                  validationMessage={errors.value?.message}
                  name='value'
                  disabled={isLoading}
                  prefix='R$ '
                  control={control}
                />
              </Grid>
              <Grid {...normalInput}>
                <NumberInput
                  id='input-discount'
                  label='Desconto'
                  validationMessage={errors.discount?.message}
                  name='discount'
                  disabled={isLoading}
                  suffix='%'
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid {...buttonSection}>
              <Grid {...buttonSize}>
                <LoadingButton
                  id='button-submit'
                  label={id != 'new' ? 'Alterar' : 'Cadastrar'}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </LoadingPage>
  );
};

import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';

import UpdateIcon from '@mui/icons-material/Edit';

import { PartnerResponse } from 'hooks/api/Partners/interfaces/partner.response';
import { format } from 'utils/dateUtilts';

export const partnerTableColumns = ({
  handleDelete,
}: {
  handleDelete: (id: string) => void;
}): GridColDef<PartnerResponse>[] => {
  return [
    {
      field: 'user.id',
      headerName: 'ID',
      width: 100,
      valueGetter: ({
        row: {
          user: { id },
        },
      }) => id,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aprovar?',
      sortable: false,
      getActions: ({ row }: GridRowParams<PartnerResponse>) => [
        <GridActionsCellItem
          key={`edit-${row.id}`}
          icon={<UpdateIcon />}
          onClick={() => handleDelete(String(row.id))}
          label='Aprovar/Rejeitar'
        />,
      ],
    },
    {
      field: 'enable',
      headerName: 'Ativo',
      sortable: false,
      width: 100,
      valueGetter: ({
        row: {
          user: { deletedAt },
        },
      }) => {
        return !deletedAt ? 'Sim' : 'Não';
      },
    },
    {
      field: 'user.name',
      headerName: 'Nome',
      valueGetter: ({
        row: {
          user: { name },
        },
      }) => name,
    },
    {
      field: 'user.email',
      headerName: 'Email',
      width: 200,
      valueGetter: ({
        row: {
          user: { email },
        },
      }) => email,
    },
    {
      field: 'user.phone',
      headerName: 'Telefone',
      width: 150,
      valueGetter: ({
        row: {
          user: { phone },
        },
      }) => phone,
    },
    {
      field: 'user.last_lgpd',
      headerName: 'Última LGPD',
      width: 150,
      valueGetter: ({
        row: {
          user: { last_lgpd },
        },
      }) => {
        return format(new Date(last_lgpd));
      },
    },    
    {
      field: 'postalCode',
      headerName: 'CEP',
      width: 200,
      valueGetter: ({ row: { postalCode } }) => postalCode ?? '-',
    },
    {
      field: 'state',
      headerName: 'Estado',
      width: 200,
      valueGetter: ({ row: { state } }) => state,
    },
    {
      field: 'city',
      headerName: 'Cidade',
      width: 200,
      valueGetter: ({ row: { city } }) => city,
    },
    {
      field: 'neighborhood',
      headerName: 'Bairro',
      width: 200,
      valueGetter: ({ row: { neighborhood } }) => neighborhood,
    },
    {
      field: 'address',
      headerName: 'Endereço',
      width: 200,
      valueGetter: ({ row: { address } }) => address,
    },
    {
      field: 'user.deletedAt',
      headerName: 'Arquivado em',
      width: 200,
      valueGetter: ({
        row: {
          user: { deletedAt },
        },
      }) => {
        return deletedAt ? format(new Date(deletedAt)) : '';
      },
    }    
  ];
};

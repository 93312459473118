import { useEffect, useState } from 'react';

import { useAuth } from 'hooks/auth';
import { HomeUI } from './home.ui';

export const HomeContainer = () => {
  const [name, setName] = useState<string>('');
  const { getUserData } = useAuth();

  useEffect(() => {
    const { name } = getUserData();
    setName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <HomeUI {...{ name }} />;
};

import { Control, Controller } from 'react-hook-form';
import { CampaignFormInputs } from '../interfaces/CampaignFormInputs';
import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { SpecialtyEntityResponse } from 'hooks/api/Specialty/interfaces/specialtySearch.response';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const PracticeModal = ({
  control,
  visible,
  specialties,
  handleClose,
}: {
  control: Control<CampaignFormInputs>;
  visible: boolean;
  specialties: SpecialtyEntityResponse[];
  handleClose: () => void;
}) => {
  const [name, setName] = useState<string>('');
  const [selectedSpecialty, setSelectedSpecialty] = useState<number>();
  const [nameError, setNameError] = useState<string>();
  const [specialtyError, setSpecialtyError] = useState<string>();
  const theme = useTheme();

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
          <TextField
            label='Nome'
            id='input-searchDoctors'
            type={'text'}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!nameError}
            helperText={nameError}
            fullWidth
          />
        </FormControl>
        <Controller
          name={'practices'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <FormControl fullWidth error={!!specialtyError}>
                <InputLabel color='secondary' id='demo-simple-select-label'>
                  Especialidade
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='select-specialty'
                  value={selectedSpecialty}
                  label={'Especialidade'}
                  onChange={(e) => setSelectedSpecialty(Number(e.target.value))}
                  color='secondary'
                  fullWidth
                >
                  {specialties.map(({ id, name }) => (
                    <MenuItem
                      id={`select-specialty-${name}`}
                      key={id}
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {specialtyError && (
                  <FormHelperText>{specialtyError}</FormHelperText>
                )}
              </FormControl>
              <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  id={'button-close-modal'}
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    let values = [...value];
                    if (name === '') {
                      setNameError('Nome é obrigatório');
                      return;
                    } else if (value.some((item) => item.name === name)) {
                      setNameError('Consultório já existe nesse mutirão');
                      return;
                    }
                    if (!selectedSpecialty) {
                      setSpecialtyError('Selecione uma especialidade');
                      return;
                    }

                    const obj = {
                      name: name,
                      doctorId: -1,
                      specialtyId: selectedSpecialty,
                      hours: [],
                    };
                    values.push(obj);
                    onChange(values);
                    setName('');
                    setSelectedSpecialty(undefined);
                    handleClose();
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          )}
        />
      </Box>
    </Modal>
  );
};

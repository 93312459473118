import { useState } from 'react';

export const usePagination = <T,>() => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [field, setField] = useState<keyof T>();
  const [order, setOrder] = useState<'ASC' | 'DESC'>();
  const [data, setData] = useState<T[]>([]);

  return {
    page,
    limit,
    total,
    field,
    data,
    order,
    setPage,
    setLimit,
    setTotal,
    setField,
    setData,
    setOrder,
  };
};

import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'hooks/alert';
import { usePlanService } from 'hooks/api/Plans';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { RecoverPasswordInputs } from './interfaces/RecoverPasswordInputs';
import { RecoverPasswordUI } from './recoverPassword';
import { makeRequestBody } from './utils/makeRequestBody';
import { planEmpty } from './utils/plan.empty';
import { schema } from './validations/validation';
import { useUserService } from 'hooks/api/Users';

export const RecoverPasswordContainer = () => {
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);

  const { patchUpdatePassword } = useUserService();
  const { token } = useParams();
  const navigate = useNavigate();
  const { success } = useAlert();

  const formMethods = useForm<RecoverPasswordInputs>({
    defaultValues: planEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    control,
  } = formMethods;

  const onSubmit = handleSubmit(async (data: RecoverPasswordInputs) => {
    if (!token) {
      return;
    }
    const body = makeRequestBody(data, token);
    let promise: Promise<any>;
    promise = patchUpdatePassword({ body });
    await promise
      .then((res) => {
        success('Senha alterada com sucesso, você ja pode fechar essa pagina!');
      })
      .catch((err) => console.log(err));
  });

  return (
    <FormProvider {...formMethods}>
      <RecoverPasswordUI
        {...{
          onSubmit,
          register,
          isLoading,
          errors,
          isLoadingGetData,
          control,
        }}
      />
    </FormProvider>
  );
};

import { useApi } from 'hooks/api';

import { PaginatedRequest } from '../interfaces/paginated.request';
import { PaginatedResponse } from '../interfaces/paginated.response';
import { PlanRequest } from './interfaces/plan.request';
import { PlanResponse } from './interfaces/plan.response';

export const usePlanService = () => {
  const { post, put, get, del, patch } = useApi();

  const postPlan = async ({ body }: PlanRequest) => {
    return post('/plan/create', body);
  };

  const putUpdatePlan = async ({ body }: PlanRequest, id: number) => {
    return put('/plan/update/' + id, body);
  };

  const getPlan = async (id: number) => {
    const { data } = await get<PlanResponse>('/plan/find-one/' + id);
    return data;
  };

  const getPLansPaginated = async (
    params: PaginatedRequest<PlanResponse>
  ): Promise<PaginatedResponse<PlanResponse>> => {
    const { data } = await get<PaginatedResponse<PlanResponse>>(
      '/plan/find-all/',
      { params }
    );
    return data;
  };

  const deletePlan = async (id: string) => {
    del('/plan/delete/' + id);
  };

  const restorePlan = async (id: number): Promise<void> => {
    await patch(`/plan/restore/${id}`);
  };

  return {
    postPlan,
    putUpdatePlan,
    getPlan,
    getPLansPaginated,
    deletePlan,
    restorePlan,
  };
};

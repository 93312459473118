import { Grid, Typography, useTheme } from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InputText } from 'components/input';
import { LoadingButton } from 'components/loadingButton';
import { LoadingPage } from 'components/loadingPage';
import { Dropdown } from 'components/select';

import { OptionResponse } from 'hooks/api/options/interfaces/option.response';
import { useInputsStyle } from 'hooks/inputsStyle';

import { UserFormInputs } from './interfaces/UserFormInputs';
import { MaskedInput } from 'components/maskedInput';

export const UsersFormUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  id,
  control,
  options,
  isLoadingGetData,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<UserFormInputs>;
  errors: FieldErrors<UserFormInputs>;
  isLoading: boolean;
  id: string | undefined;
  control: Control<UserFormInputs>;
  options: OptionResponse[];
  isLoadingGetData: boolean;
}) => {
  const theme = useTheme();
  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });

  return (
    <LoadingPage isLoading={isLoadingGetData}>
      <Grid {...container}>
        <Grid item xs={12}>
          <Grid item xs={12} mb={3}>
            <Typography variant='h4' component='h1' color='secondary'>
              {id === 'new' ? 'Criar Usuário' : `Alterar Usuário #${id}`}
            </Typography>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-name'
                  label='Nome'
                  validationMessage={errors.name?.message}
                  register={register('name')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                {/*<InputText
                  label='Telefone'
                  validationMessage={errors.phone?.message}
                  register={register('phone')}
                  disabled={isLoading}
                />*/}
                <MaskedInput
                  id='input-phone'
                  label='Telefone'
                  validationMessage={errors.phone?.message}
                  register={register('phone')}
                  disabled={isLoading}
                  mask={['(00) 0000-0000', '(00) 00000-0000']}
                  control={control}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-email'
                  label='Email'
                  validationMessage={errors.email?.message}
                  register={register('email')}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <Dropdown<UserFormInputs>
                  id='dropdown-type'
                  control={control}
                  label='Tipo'
                  name='type'
                  disabled={isLoading}
                  key='type'
                  validationMessage={errors.type?.message}
                  options={options}
                />
              </Grid>
            </Grid>
            <Grid {...buttonSection}>
              <Grid {...buttonSize}>
                <LoadingButton
                  id='button-submit'
                  label={id ? 'Alterar' : 'Cadastrar'}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </LoadingPage>
  );
};

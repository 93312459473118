import { Grid, Typography, useTheme } from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InputText } from 'components/input';
import { LoadingButton } from 'components/loadingButton';

import { NumberInput } from 'components/numberInput';
import { LoadingPage } from '../../components/loadingPage';
import { useInputsStyle } from '../../hooks/inputsStyle';
import { RecoverPasswordInputs } from './interfaces/RecoverPasswordInputs';

export const RecoverPasswordUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  isLoadingGetData,
  control,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<RecoverPasswordInputs>;
  errors: FieldErrors<RecoverPasswordInputs>;
  isLoading: boolean;
  isLoadingGetData: boolean;
  control: Control<RecoverPasswordInputs>;
}) => {
  const theme = useTheme();

  const {
    palette: { primary },
    spacing,
  } = useTheme();
  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });

  return (
    <Grid
      container
      sx={{ height: '97vh' }}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        item
        xs={11}
        md={10}
        lg={5}
        xl={4}
        sx={{
          border: `1px solid ${primary.main}`,
          borderRadius: spacing(1),
          padding: spacing(2),
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h4'
            component='h1'
            color='secondary'
            align='center'
          >
            Recuperar Senha
          </Typography>
        </Grid>
        <form onSubmit={onSubmit}>
          <Grid container item xs={12} justifyContent='right'>
            <Grid item mb={3} xs={12}>
              <InputText
                id='input-password'
                label='Senha'
                type='password'
                validationMessage={errors.password?.message}
                register={register('password')}
                disabled={isLoading}
              />
            </Grid>
            <Grid item mb={3} xs={12}>
              <InputText
                id='input-confirm-password'
                label='Confirmar Senha'
                type='password'
                validationMessage={errors.confirmPassword?.message}
                register={register('confirmPassword')}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <LoadingButton
                id='button-submit'
                label='Alterar'
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

import { PartnerFormInputs } from '../interfaces/PartnerFormInputs';
import { PartnerRequestBody } from 'hooks/api/Partners/interfaces/partner.request';

export const makeRequestBody = (obj: PartnerFormInputs): PartnerRequestBody => {
  return {
    name: obj.name,
    phone: obj.phone,
    email: obj.email,
    type: '',
    password: obj.password,
    confirmPassword: obj.confirmPassword,
    tax_id: obj.tax_id,
    address: obj.address,
    address2: obj.address2,
    address_number: obj.address_number,
    city: obj.city,
    state: obj.state,
    neighborhood: obj.neighborhood,
    postal_code: obj.postal_code,
  };
};

import { useEffect, useState } from 'react';

import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';

import { useAlert } from 'hooks/alert';
import { useDoctorsService } from 'hooks/api/Doctors';
import { DoctorResponse } from 'hooks/api/Doctors/interfaces/doctor.response';
import { useUserService } from 'hooks/api/Users';
import { useDialog } from 'hooks/dialog';
import { usePagination } from 'hooks/pagination';

import { doctorTableColumns } from './doctors.columns';
import { DoctorUI } from './doctors.ui';

export const DoctorContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    getDoctors: getRequest,
    rejectDoctor,
    approveDoctor,
  } = useDoctorsService();
  const { deleteUser: deleteRequest, restoreUser: restorRequest } =
    useUserService();
  const {
    data,
    page,
    limit,
    field,
    order,
    total,
    setField,
    setOrder,
    setData,
    setLimit,
    setPage,
    setTotal,
  } = usePagination<DoctorResponse>();
  const { handleOpenDialog, handleCloseDialog } = useDialog();
  const { success, error } = useAlert();

  const handleDelete = async (id: string) => {
    rejectDoctor(Number(id))
      .then((response) => {
        success('Médico rejeitado com sucesso!');
        handleFetchData();
      })
      .catch((err) => {
        error('Não foi possível alterar o status do médico.');
      });
  };

  const handleRestore = async (id: string) => {
    approveDoctor(Number(id))
      .then((response) => {
        success('Médico aprovado com sucesso!');
        handleFetchData();
      })
      .catch((err) => {
        error('Não foi possível alterar o status do médico.');
      });
  };

  const handleDeleteDialog = async (id: string) => {
    handleOpenDialog(
      'Atenção',
      'Deseja aprovar ou rejeitar o cadastro do médico?',
      [
        {
          label: 'Cancelar',
          action: handleCloseDialog,
        },
        {
          label: 'Rejeitar',
          isCancel: true,
          action: () => {
            handleDelete(id).then(() => {
              handleCloseDialog();
            });
          },
        },
        {
          label: 'Aprovar',
          isCancel: true,
          action: () => {
            handleRestore(id).then(() => {
              handleCloseDialog();
            });
          },
        },
      ]
    );
  };

  const handleFetchData = async () => {
    setIsLoading(true);
    getRequest({ type: 'ADMIN', page, limit, field, order })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  };

  const handlePaginationStateChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    if (page >= 0) {
      setPage(page);
    }
    setLimit(pageSize);
  };

  const handlerSortModelChange = (newModel: GridSortModel) => {
    if (newModel?.length === 0) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    const { field, sort } = newModel[0];

    if (!field) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    setField(field as keyof DoctorResponse);
    setOrder(sort === 'asc' ? 'ASC' : 'DESC');
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, field, order]);

  const columns: GridColDef<DoctorResponse>[] = doctorTableColumns({
    handleDelete: handleDeleteDialog,
  });

  return (
    <DoctorUI
      {...{
        rows: data,
        columns,
        isLoading,
        handlePaginationStateChange,
        handlerSortModelChange,
        paginationState: { page, pageSize: limit },
        totalRows: total,
      }}
    />
  );
};

import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'hooks/alert';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { makeRequestBody } from './utils/makeRequestBody';
import { planEmpty } from './utils/nurse.empty';
import { schema } from './validations/validation';
import { NurseFormInputs } from './interfaces/NurseFormInputs';
import { useNurseService } from 'hooks/api/Nurse';
import { useUserService } from 'hooks/api/Users';
import { NurseFormUI } from './nurseForm.ui';
import { NurseResponse } from 'hooks/api/Nurse/interfaces/nurse.response';

export const NurseFormContainer = () => {
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);
  const [nurse, setNurse] = useState<NurseResponse>();

  const { postNurse, getNurse } = useNurseService();
  const { putUpdateUserNurse } = useUserService();
  const { id } = useParams();
  const navigate = useNavigate();
  const { success, error } = useAlert();

  const formMethods = useForm<NurseFormInputs>({
    defaultValues: planEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    setError,
    control,
  } = formMethods;

  useEffect(() => {
    if (id === 'new') return;
    setIsLoadingGetData(true);
    getNurse(Number(id))
      .then((result) => {
        setNurse(result);
        setValue('name', result.user.name);
        setValue('email', result.user.email);
        setValue('phone', result.user.phone);
      })
      .finally(() => setIsLoadingGetData(false));
  }, [id]);

  const onSubmit = handleSubmit(async (data: NurseFormInputs) => {
    const body = makeRequestBody(data);
    let promise: Promise<any>;
    if (id === 'new') {
      if (data.password === '') {
        setError('password', {
          type: 'manual',
          message: 'Senha é obrigatória',
        });
        return;
      }
      if (data.password !== data.confirmPassword) {
        setError('confirmPassword', {
          type: 'manual',
          message: 'As senhas não coincidem',
        });
        return;
      }
      promise = postNurse({ body });
    } else {
      promise = putUpdateUserNurse({ body }, Number(nurse?.user.id));
    }
    await promise
      .then((res) => {
        success('Enfermeiro(a) salvo com sucesso!');
        navigate(ApplicationRoutes.NURSES);
      })
      .catch((err) => {
        console.log(err);
        error(err.response.data.message);
      });
  });

  return (
    <FormProvider {...formMethods}>
      <NurseFormUI
        {...{
          onSubmit,
          register,
          isLoading,
          errors,
          id,
          isLoadingGetData,
          control,
        }}
      />
    </FormProvider>
  );
};

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Archive';
import UpdateIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Unarchive';

import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';

import { useAlert } from 'hooks/alert';
import { PlanResponse } from 'hooks/api/Plans/interfaces/plan.response';
import { useUserService } from 'hooks/api/Users';
import { UserResponse } from 'hooks/api/Users/interfaces/user.response';
import { useDialog } from 'hooks/dialog';
import { usePagination } from 'hooks/pagination';

import { format } from 'utils/dateUtilts';
import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';

import { UsersUI } from './users.ui';

export const UsersContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    getAdminUsers: getRequest,
    deleteUser: deleteRequest,
    restoreUser: restorRequest,
  } = useUserService();
  const {
    data,
    page,
    limit,
    field,
    order,
    total,
    setField,
    setOrder,
    setData,
    setLimit,
    setPage,
    setTotal,
  } = usePagination<UserResponse>();
  const navigate = useNavigate();
  const { handleOpenDialog, handleCloseDialog } = useDialog();
  const { success } = useAlert();

  const handleNavigateToForm = (id?: string) => {
    navigate(ApplicationRoutes.USERS_FORM.replace(':id', id ?? 'new'));
  };

  const handleDelete = async (id: string) => {
    await deleteRequest(Number(id));
    success('Usuário arquivado com sucesso!');
    handleFetchData();
  };

  const handleDeleteDialog = async (id: string) => {
    handleOpenDialog('Atenção', 'Deseja realmente arquivar esse usuário?', [
      {
        label: 'Cancelar',
        action: handleCloseDialog,
      },
      {
        label: 'Arquivar',
        isCancel: true,
        action: () => {
          handleDelete(id).then(() => {
            handleCloseDialog();
          });
        },
      },
    ]);
  };

  const handleRestore = async (id: string) => {
    await restorRequest(Number(id));
    success('Usuário desarquivado com sucesso!');
    handleFetchData();
  };

  const handleRestoreDialog = async (id: string) => {
    handleOpenDialog('Atenção', 'Deseja realmente desarquivar esse usuário?', [
      {
        label: 'Cancelar',
        action: handleCloseDialog,
      },
      {
        label: 'Desarquivar',
        isCancel: true,
        action: () => {
          handleRestore(id).then(() => {
            handleCloseDialog();
          });
        },
      },
    ]);
  };

  const handleFetchData = async () => {
    setIsLoading(true);
    getRequest({ page, limit, field, order })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  };

  const handlePaginationStateChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    if (page >= 0) {
      setPage(page);
    }
    setLimit(pageSize);
  };

  const handlerSortModelChange = (newModel: GridSortModel) => {
    if (newModel?.length === 0) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    const { field, sort } = newModel[0];

    if (!field) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    setField(field as keyof UserResponse);
    setOrder(sort === 'asc' ? 'ASC' : 'DESC');
  };

  useEffect(() => {
    handleFetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, field, order]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Nome', width: 100 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Telefone', width: 100 },
    {
      field: 'last_lgpd',
      headerName: 'Última LGPD',
      width: 150,
      valueGetter: ({ row: { last_lgpd } }) => {
        return format(new Date(last_lgpd));
      },
    },
    { field: 'type', headerName: 'Permissão', width: 100 },
    {
      field: 'enable',
      headerName: 'Ativo',
      width: 100,
      valueGetter: ({ row: { deletedAt } }) => {
        return !deletedAt ? 'Sim' : 'Não';
      },
    },
    {
      field: 'deletedAt',
      headerName: 'Arquivado em',
      width: 200,
      valueGetter: ({ row: { deletedAt } }) => {
        return deletedAt ? format(new Date(deletedAt)) : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      getActions: ({ row: { deletedAt, id } }: GridRowParams<PlanResponse>) => [
        <GridActionsCellItem
          key={deletedAt ? `${id}-restore` : `${id}-delete`}
          icon={deletedAt ? <RestoreIcon /> : <DeleteIcon />}
          onClick={() =>
            deletedAt
              ? handleRestoreDialog(String(id))
              : handleDeleteDialog(String(id))
          }
          label='Delete'
        />,
        <GridActionsCellItem
          key={`${id}-update`}
          icon={<UpdateIcon />}
          onClick={() => {
            handleNavigateToForm(String(id));
          }}
          label='Update'
        />,
      ],
    },
  ];

  return (
    <UsersUI
      {...{
        rows: data,
        columns,
        isLoading,
        handleNavigateToForm,
        handlePaginationStateChange,
        handlerSortModelChange,
        paginationState: { page, pageSize: limit },
        totalRows: total,
      }}
    />
  );
};

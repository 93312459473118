import { createContext } from 'react';

type UserInfoResponse = {
  id: number;
  name: string;
  type: string;
};

export interface AuthContextData {
  isSigned: boolean;
  signIn(token: string, user: UserInfoResponse): void;
  signOut: () => void;
  getToken(): string | null;
  getUserData(): UserInfoResponse;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export default AuthContext;

import { useApi } from 'hooks/api';
import { PaginatedResponse } from 'hooks/api/interfaces/paginated.response';

import { PaginatedRequest } from '../interfaces/paginated.request';
import { UserRequest } from './interfaces/user.request';
import { UserResponse } from './interfaces/user.response';
import { RecoverPasswordRequest } from './interfaces/recoverPassword.request';
import { UserUpdateRequest } from './interfaces/user.update.request';

export const useUserService = () => {
  const { post, put, get, del, patch } = useApi();

  const postUser = async ({ body }: UserRequest) => {
    return post('/user/create-admin', body);
  };

  const putUpdateUser = async ({ body }: UserRequest, id: number) => {
    return put(`/user/update/${id}`, body);
  };

  const putUpdateUserNurse = async (
    { body }: UserUpdateRequest,
    id: number
  ) => {
    return put(`/user/update/${id}`, body);
  };

  const patchUpdatePassword = async ({ body }: RecoverPasswordRequest) => {
    return patch(`/user/recover-password`, body);
  };

  const getUser = async (id: number) => {
    const { data } = await get<UserResponse>(`/user/find-one/${id}`);
    return data;
  };

  const getAdminUsers = async (
    params: PaginatedRequest<UserResponse>
  ): Promise<PaginatedResponse<UserResponse>> => {
    const { data } = await get<PaginatedResponse<UserResponse>>(
      '/user/find-admins',
      { params }
    );
    return data;
  };

  const deleteUser = async (id: number) => {
    return del(`/user/delete/${id}`);
  };

  const restoreUser = async (id: number): Promise<void> => {
    await patch(`/user/restore/${id}`);
  };

  return {
    getUser,
    postUser,
    putUpdateUser,
    putUpdateUserNurse,
    getAdminUsers,
    deleteUser,
    restoreUser,
    patchUpdatePassword,
  };
};

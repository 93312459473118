import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'hooks/alert';
import { useUserService } from 'hooks/api/Users';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';

import { OptionResponse } from '../../hooks/api/options/interfaces/option.response';
import { UserFormInputs } from './interfaces/UserFormInputs';
import { UsersFormUI } from './usersForm.ui';
import { makeRequestBody } from './utils/makeRequestBody';
import { userEmpty } from './utils/user.empty';
import { schema } from './validations/validation';

export const UserFormContainer = () => {
  const [options] = useState<OptionResponse[]>([
    { value: 'AdminDoctor', label: 'Secretária do Médico' },
    { value: 'ValidationAppointmentDoctor', label: 'Aprovador de Consultas' },
    { value: 'Admin', label: 'Administrador' },
    { value: 'AdminPartner', label: 'Admin do parceiro' },
    { value: 'ValidationDiscountPartner', label: 'Gerenciador de descontos do Parceiro' },
  ]);
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);

  const { getUser, postUser, putUpdateUser } = useUserService();
  const { id } = useParams();
  const navigate = useNavigate();
  const formMethods = useForm<UserFormInputs>({
    defaultValues: userEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const { success } = useAlert();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    control,
    watch,
  } = formMethods;

  useEffect(() => {
    if (id === 'new') return;
    setIsLoadingGetData(true);
    getUser(Number(id))
      .then((result) => {
        setValue('name', result.name);
        setValue('phone', result.phone);
        setValue('type', result.type);
        setValue('email', result.email);
      })
      .finally(() => {
        setIsLoadingGetData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    console.log(watch('phone'));
  }, [watch('phone')]);

  const onSubmit = handleSubmit(async (data: UserFormInputs) => {
    const body = makeRequestBody(data);
    let promise: Promise<any>;
    if (id === 'new') {
      promise = postUser({ body });
    } else {
      promise = putUpdateUser({ body }, Number(id));
    }
    await promise
      .then((res) => {
        navigate(ApplicationRoutes.USERS);
        success('Dados usuário salvos com sucesso!');
      })
      .catch((err) => console.error(err));
  });

  return (
    <UsersFormUI
      onSubmit={onSubmit}
      register={register}
      errors={errors}
      isLoading={isLoading}
      control={control}
      id={id}
      isLoadingGetData={isLoadingGetData}
      options={options}
    />
  );
};

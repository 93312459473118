import { JSX } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from 'hooks/auth';

import { Home } from 'pages/home';
import { Login } from 'pages/login';
import { Plans } from 'pages/plans';
import Users from 'pages/users';
import { PlansForm } from 'pages/plansForm';
import { ProtectedRoutes } from './protectedRoutes';
import { UsersForm } from 'pages/usersForm';
import { Doctors } from 'pages/doctors';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { PartnersForm } from 'pages/partnersForm';
import { Partners } from '../pages/partners';
import { RecoverPassword } from 'pages/recoverPassword';
import { Campaign } from 'pages/campaign';
import { CampaignForm } from 'pages/campaignForm';
import { Nurses } from 'pages/nurses';
import { NurseForm } from 'pages/nursesForm';
import Report from 'pages/report';
import { ProtectedRoutesNoLayout } from './protectedRoutesNoLayout';

export const AppRoutes = () => {
  const { isSigned } = useAuth();

  const privateRoutes: {
    route: string;
    element: JSX.Element;
  }[] = [
    {
      route: ApplicationRoutes.HOME,
      element: <Home />,
    },
    {
      route: ApplicationRoutes.USERS,
      element: <Users />,
    },
    {
      route: ApplicationRoutes.USERS_FORM,
      element: <UsersForm />,
    },
    {
      route: ApplicationRoutes.PLANS,
      element: <Plans />,
    },
    {
      route: ApplicationRoutes.PLANS_FORM,
      element: <PlansForm />,
    },
    {
      route: ApplicationRoutes.NURSES,
      element: <Nurses />,
    },
    {
      route: ApplicationRoutes.NURSES_FORM,
      element: <NurseForm />,
    },
    {
      route: ApplicationRoutes.CAMPAIGN,
      element: <Campaign />,
    },
    {
      route: ApplicationRoutes.CAMPAIGN_FORM,
      element: <CampaignForm />,
    },
    {
      route: ApplicationRoutes.DOCTORS,
      element: <Doctors />,
    },
    {
      route: ApplicationRoutes.PARTNERS,
      element: <Partners />,
    },
  ];

  return (
    <Routes>
      <Route
        path={ApplicationRoutes.SIGN_IN}
        element={
          isSigned ? (
            <Navigate to={ApplicationRoutes.HOME} replace />
          ) : (
            <Login />
          )
        }
      />

      <Route
        key={ApplicationRoutes.PARTNERS_FORM}
        path={ApplicationRoutes.PARTNERS_FORM}
        element={<PartnersForm />}
      />

      <Route
        key={ApplicationRoutes.APPOINTMENT_REPORT}
        path={ApplicationRoutes.APPOINTMENT_REPORT}
        element={
          <ProtectedRoutesNoLayout>
            <Report />
          </ProtectedRoutesNoLayout>
        }
      />

      <Route
        key={ApplicationRoutes.RECOVER_PASSWORD}
        path={ApplicationRoutes.RECOVER_PASSWORD}
        element={<RecoverPassword />}
      />
      <Route path={'*'} element={<div>Não encontrado</div>} />

      {privateRoutes.map(({ element, route }) => (
        <Route
          key={route}
          path={route}
          element={<ProtectedRoutes>{element}</ProtectedRoutes>}
        />
      ))}
    </Routes>
  );
};

import { useApi } from 'hooks/api';

import { PaginatedRequest } from '../interfaces/paginated.request';
import { PaginatedResponse } from '../interfaces/paginated.response';
import { CampaignRequest } from './interfaces/campaign.request';
import { CampaignResponse } from './interfaces/campaign.response';

export const useCampaignService = () => {
  const { post, put, get, del, patch } = useApi();

  const postCampaign = async ({ body }: CampaignRequest) => {
    return post('/campaign/create', body);
  };

  const putUpdateCampaign = async ({ body }: CampaignRequest, id: number) => {
    return put('/campaign/update/' + id, body);
  };

  const getCampaign = async (id: number) => {
    const { data } = await get<CampaignResponse>('/campaign/' + id);
    return data;
  };

  const getCampaignsPaginated = async (
    params: PaginatedRequest<CampaignResponse>
  ): Promise<PaginatedResponse<CampaignResponse>> => {
    const { data } = await get<PaginatedResponse<CampaignResponse>>(
      '/campaign/',
      { params }
    );
    return data;
  };

  const deleteCampaign = async (id: string) => {
    del('/campaign/' + id);
  };

  const restoreCampaign = async (id: number): Promise<void> => {
    await patch(`/campaign/restore/${id}`);
  };

  return {
    postCampaign,
    putUpdateCampaign,
    getCampaign,
    getCampaignsPaginated,
    deleteCampaign,
    restoreCampaign,
  };
};

import { JSX, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import CashIcon from '@mui/icons-material/MoneySharp';
import UsersGroupIcon from '@mui/icons-material/PeopleAltSharp';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformationSharp';
import MedicalIcon from '@mui/icons-material/LocalHospital';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Groups from '@mui/icons-material/Groups';

import { AppBarMenuSetting } from 'components/appBar/interfaces/AppBarMenuSetting';
import { AppDrawerProps } from 'components/appDrawer/interfaces/AppDrawerProps';

import { useAuth } from 'hooks/auth';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';

import { LayoutUI } from './layout.ui';

export const LayoutContainer = ({ children }: { children: JSX.Element }) => {
  const isLargeScreen = useMediaQuery('(min-width:1280px)');

  const [userName, setUserName] = useState('');
  const [isOpenSettings, setOpenSettings] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(isLargeScreen);
  const [menuSettings, setMenuSettings] = useState<AppBarMenuSetting[]>([]);
  const [sideMenus, setSideMenus] = useState<AppDrawerProps['menus']>([]);

  const { signOut, getUserData, isSigned } = useAuth();
  const navigate = useNavigate();

  const allMenus: AppDrawerProps['menus'] = [
    {
      id: 1,
      name: 'Home',
      handleOnclick: () => navigate(ApplicationRoutes.HOME),
      icon: <HomeIcon />,
      roles: [],
    },
    {
      id: 2,
      name: 'Planos',
      handleOnclick: () => navigate(ApplicationRoutes.PLANS),
      icon: <CashIcon />,
      roles: ['admin'],
    },
    {
      id: 3,
      name: 'Mutirões',
      handleOnclick: () => navigate(ApplicationRoutes.CAMPAIGN),
      icon: <Groups />,
      roles: ['admin'],
    },
    {
      id: 4,
      name: 'Usuários',
      handleOnclick: () => navigate(ApplicationRoutes.USERS),
      icon: <UsersGroupIcon />,
      roles: ['admin'],
    },
    {
      id: 5,
      name: 'Médicos',
      handleOnclick: () => navigate(ApplicationRoutes.DOCTORS),
      icon: <MedicalInformationIcon />,
      roles: ['admin'],
    },
    {
      id: 6,
      name: 'Enfermeiros(as)',
      handleOnclick: () => navigate(ApplicationRoutes.NURSES),
      icon: <MedicalIcon />,
      roles: ['admin'],
    },
    {
      id: 7,
      name: 'Parceiros',
      handleOnclick: () => navigate(ApplicationRoutes.PARTNERS),
      icon: <HandshakeIcon />,
      roles: ['admin'],
    },
  ];

  const handleSignOut = () => {
    //TODO call singout endpoint
    signOut();
  };

  const fetchMenuSettings = () => {
    const menuSettings: AppBarMenuSetting[] = [
      {
        name: 'Meu perfil',
        handleClick: () => navigate('/profile'),
      },
      {
        name: 'Sair',
        handleClick: handleSignOut,
      },
    ];

    setMenuSettings(menuSettings);
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    fetchMenuSettings();
    const { name } = getUserData();
    setUserName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSigned) {
      setSideMenus([]);
      return;
    }

    const { type: role } = getUserData();
    const filteredMenus = allMenus.filter((menu) => {
      if (menu.roles.length === 0) return true;
      return menu.roles.includes(role.toLowerCase());
    });

    setSideMenus(filteredMenus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned]);

  return (
    <LayoutUI
      appBarProps={{
        userName,
        isOpenSettings,
        menuSettings,
        isMenuOpen,
        handleOpenSettings,
        handleCloseSettings,
        handleOpenMenu,
      }}
      appDrawerProps={{
        open: isMenuOpen,
        closeDrawer: handleCloseMenu,
        menus: sideMenus,
      }}
    >
      {children}
    </LayoutUI>
  );
};

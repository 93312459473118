import { Control, Controller } from 'react-hook-form';
import { CampaignFormInputs } from '../interfaces/CampaignFormInputs';
import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { SpecialtyEntityResponse } from 'hooks/api/Specialty/interfaces/specialtySearch.response';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IMaskInput } from 'react-imask';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const HoursModal = ({
  control,
  visible,
  handleClose,
  selectedPractice,
}: {
  control: Control<CampaignFormInputs>;
  visible: boolean;
  handleClose: () => void;
  selectedPractice: string | undefined;
}) => {
  const [date, setDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');
  const [startTimeError, setStartTimeError] = useState<string>('');
  const [endTimeError, setEndTimeError] = useState<string>('');
  const theme = useTheme();

  const validate = () => {
    if (date === '') {
      setDateError('Selecione uma data');
      return false;
    }
    if (startTime === '') {
      setStartTimeError('Insira um horario de início');
      return false;
    }

    if (endTime === '') {
      setEndTimeError('Insira um horario de encerramento');
      return false;
    }
    return true;
  };

  const clear = () => {
    setDate('');
    setStartTime('');
    setEndTime('');
    setDateError('');
    setStartTimeError('');
    setEndTimeError('');
  };

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
          <DatePicker
            name={'input-date'}
            value={dayjs(date)}
            onChange={(date) => setDate(dayjs(date).format('YYYY-MM-DD'))}
            label='Data'
            slotProps={{ textField: { fullWidth: true, id: 'input-date' } }}
          />
          {dateError && (
            <FormHelperText style={{ color: '#D32F2F' }}>
              {dateError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
          <TextField
            id={'input-start-time'}
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            label={'Início'}
            error={!!startTimeError}
            helperText={startTimeError}
            placeholder={'00:00'}
            InputProps={{
              inputComponent: IMaskInput,
              inputProps: {
                mask: '00:00',
                overwrite: true,
              },
            }}
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <TextField
            id={'input-end-time'}
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            label={'Fim'}
            error={!!endTimeError}
            helperText={endTimeError}
            placeholder={'00:00'}
            InputProps={{
              inputComponent: IMaskInput,
              inputProps: {
                mask: '00:00',
                overwrite: true,
              },
            }}
          />
        </FormControl>
        <div style={{ maxHeight: '60%' }}>
          <Controller
            name={'practices'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <div
                  style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    id={'button-close-modal'}
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      let values = [...value];
                      if (!validate()) return;
                      const newValues = values.map((item) => {
                        if (item.name === selectedPractice) {
                          let items = item.hours;
                          items.push({
                            date: date,
                            startHour: startTime,
                            endHour: endTime,
                          });
                          clear();
                          return {
                            name: item.name,
                            doctorId: item.doctorId,
                            specialtyId: item.specialtyId,
                            hours: items,
                          };
                        } else {
                          return item;
                        }
                      });

                      onChange(newValues);
                      handleClose();
                    }}
                  >
                    Adicionar
                  </Button>
                </div>
              </>
            )}
          />
        </div>
      </Box>
    </Modal>
  );
};

import { FC } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBarProps,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';

import { AppBarUiProps } from './interfaces/AppBarUiProps';

export const AppBarUI: FC<AppBarUiProps> = ({
  userName,
  isOpenSettings,
  menuSettings,
  isMenuOpen: open,
  handleCloseSettings,
  handleOpenSettings,
  handleOpenMenu,
}) => {
  const drawerWidth = 240;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['all'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar>
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: open ? 'flex-end' : 'space-between',
            width: '100%',
          }}
        >
          <IconButton
            id='button-menu'
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleOpenMenu}
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon
              sx={{
                color: 'white',
                fontSize: '2rem',
              }}
            />
          </IconButton>

          <Tooltip title='Perfil e configuração'>
            <IconButton
              id='button-profile'
              sx={{ p: 0 }}
              onClick={handleOpenSettings}
            >
              <Avatar>
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '1.2rem',
                    fontWeight: 600,
                  }}
                >
                  {userName[0]}
                </Typography>
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isOpenSettings}
            onClose={handleCloseSettings}
          >
            {menuSettings.map(({ name, handleClick }) => (
              <MenuItem key={name} onClick={handleClick}>
                <Typography textAlign='center'>{name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

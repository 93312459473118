import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'hooks/alert';
import { usePlanService } from 'hooks/api/Plans';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { PlanFormInputs } from './interfaces/PlanFormInputs';
import { PlanFormUI } from './planForm.ui';
import { makeRequestBody } from './utils/makeRequestBody';
import { planEmpty } from './utils/plan.empty';
import { schema } from './validations/validation';

export const PlanFormContainer = () => {
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);

  const { postPlan, putUpdatePlan, getPlan } = usePlanService();
  const { id } = useParams();
  const navigate = useNavigate();
  const { success } = useAlert();
  const [options, setOptions] = useState([
    { value: '1', label: 'Mensal' },
    { value: '3', label: 'Trimestral' },
    { value: '6', label: 'Semestral' },
    { value: '12', label: 'Anual' },
  ]);

  const formMethods = useForm<PlanFormInputs>({
    defaultValues: planEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    control,
  } = formMethods;

  useEffect(() => {
    if (id === 'new') return;
    setIsLoadingGetData(true);
    getPlan(Number(id))
      .then((result) => {
        setValue('name', result.name);
        setValue('value', result.value.toString());
        setValue('discount', result.discount.toString());
      })
      .finally(() => setIsLoadingGetData(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = handleSubmit(async (data: PlanFormInputs) => {
    const body = makeRequestBody(data);
    let promise: Promise<any>;
    if (id === 'new') {
      promise = postPlan({ body });
    } else {
      promise = putUpdatePlan({ body }, Number(id));
    }
    await promise
      .then((res) => {
        success('Plano salvo com sucesso!');
        navigate(ApplicationRoutes.PLANS);
      })
      .catch((err) => console.log(err));
  });

  return (
    <FormProvider {...formMethods}>
      <PlanFormUI
        {...{
          onSubmit,
          register,
          isLoading,
          errors,
          id,
          isLoadingGetData,
          control,
          options,
        }}
      />
    </FormProvider>
  );
};

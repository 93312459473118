import { convertToNumberOrUndefined } from 'utils/stringUtils';
import { CampaignFormInputs } from '../interfaces/CampaignFormInputs';
import { CampaignRequestBody } from 'hooks/api/Campaign/interfaces/campaign.request';
import { DoctorSearchResponse } from 'hooks/api/Doctors/interfaces/doctorSearch.response';

export const makeRequestBody = ({
  practices,
  end,
  start,
  title,
  nurses,
}: CampaignFormInputs): CampaignRequestBody => {
  return {
    practices: practices,
    nurses: nurses.map((nurse) => nurse.id),
    end,
    start,
    title,
  };
};

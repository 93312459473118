import { FC, PropsWithChildren, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { DialogContext } from 'context/dialog';
import { ButtonsProps } from 'context/dialog/interfaces/DialogContextProps';

export const DialogProvider: FC<{ children: any }> = ({
  children,
}: {
  children: PropsWithChildren<{}>;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogButtons, setDialogButtons] = useState<ButtonsProps>([]);

  const handleOpenDialog = (
    title: string,
    content: string,
    buttons: ButtonsProps
  ) => {
    setDialogTitle(title);
    setDialogContent(content);
    setIsDialogOpen(true);
    setDialogButtons(buttons);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDialogTitle('');
    setDialogContent('');
    setDialogButtons([]);
  };

  const contextProps = useMemo(
    () => ({ handleOpenDialog, handleCloseDialog }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [isDialogOpen, dialogContent, dialogTitle, dialogButtons]
  );

  return (
    <DialogContext.Provider value={contextProps}>
      <>
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {dialogContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {dialogButtons.map(({ action, label, isCancel = false }) => (
              <Button
                key={label.replace(/\s/g, '')}
                variant='text'
                onClick={action}
                sx={{
                  color: isCancel ? 'error.main' : 'primary.main',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    fontWeight: 'bold',
                  },
                }}
              >
                {label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
        {children}
      </>
    </DialogContext.Provider>
  );
};

import { CircularProgress, Grid } from '@mui/material';
import { JSX } from 'react';

export const LoadingPage = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: JSX.Element;
}) => {
  return isLoading ? (
    <Grid
      container
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ height: '100%' }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    children
  );
};

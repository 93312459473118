import { Control, Controller, FieldPath } from 'react-hook-form';
import { CampaignFormInputs } from '../interfaces/CampaignFormInputs';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FormControl } from '@mui/base';
import SearchIcon from '@mui/icons-material/Search';
import { NurseSearchEntity } from 'hooks/api/Nurse/interfaces/nurse.search.response';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const NurseModal = ({
  control,
  visible,
  handleClose,
  searchTerm,
  setSearchTerm,
  nurses,
}: {
  control: Control<CampaignFormInputs>;
  visible: boolean;
  handleClose: () => void;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  nurses: NurseSearchEntity[];
}) => {
  const theme = useTheme();

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <FormControl>
          <OutlinedInput
            id='input-searchnurses'
            color='secondary'
            fullWidth
            autoComplete='on'
            type={'text'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <div style={{ maxHeight: '60%' }}>
          <Controller
            name={'nurses'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {nurses.map((nurse) => (
                  <FormControlLabel
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={() => {
                          if (value.some((item) => item.id === nurse.id)) {
                            const values = value;
                            let newValues = values.filter(
                              (item) => item.id !== nurse.id
                            );
                            console.log('Values', newValues);
                            onChange([...newValues]);
                          } else {
                            onChange([...value, nurse]);
                          }
                        }}
                      />
                    }
                    checked={value.some((item) => item.id === nurse.id)}
                    label={nurse.name}
                  />
                ))}
              </>
            )}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            id={'button-close-modal'}
            variant='contained'
            color='secondary'
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

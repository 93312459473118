import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Archive';
import UpdateIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Unarchive';

import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';

import { useAlert } from 'hooks/alert';
import { PlanResponse } from 'hooks/api/Plans/interfaces/plan.response';
import { useDialog } from 'hooks/dialog';
import { usePagination } from 'hooks/pagination';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { useNurseService } from 'hooks/api/Nurse';
import { NursesUI } from './nurses.ui';
import { NurseResponse } from 'hooks/api/Nurse/interfaces/nurse.response';

export const NursesContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { getSearchNurse, deleteNurse, restoreNurse } = useNurseService();
  const {
    data,
    page,
    limit,
    field,
    order,
    total,
    setField,
    setOrder,
    setData,
    setLimit,
    setPage,
    setTotal,
  } = usePagination<NurseResponse>();
  const navigate = useNavigate();
  const { handleOpenDialog, handleCloseDialog } = useDialog();
  const { success } = useAlert();

  const handleNavigateToForm = (id?: string) => {
    navigate(ApplicationRoutes.NURSES_FORM.replace(':id', id ?? 'new'));
  };

  const handleDelete = async (id: string) => {
    await deleteNurse(id);
    success('Enfermeiro(a) arquivado com sucesso!');
    handleFetchData();
  };

  const handleDeleteDialog = async (id: string) => {
    handleOpenDialog(
      'Atenção',
      'Deseja realmente arquivar esse enfermeiro(a)?',
      [
        {
          label: 'Cancelar',
          action: handleCloseDialog,
        },
        {
          label: 'Arquivar',
          isCancel: true,
          action: () => {
            handleDelete(id).then(() => {
              handleCloseDialog();
            });
          },
        },
      ]
    );
  };

  const handleRestore = async (id: string) => {
    await restoreNurse(Number(id));
    success('Enfermeiro(a) desarquivado com sucesso!');
    handleFetchData();
  };

  const handleRestoreDialog = async (id: string) => {
    handleOpenDialog(
      'Atenção',
      'Deseja realmente desarquivar esse Enfermeiro(a)?',
      [
        {
          label: 'Cancelar',
          action: handleCloseDialog,
        },
        {
          label: 'Desarquivar',
          isCancel: true,
          action: () => {
            handleRestore(id).then(() => {
              handleCloseDialog();
            });
          },
        },
      ]
    );
  };

  const handleFetchData = async () => {
    setIsLoading(true);
    getSearchNurse({ page, limit, field, order })
      .then(({ data: newData, total }) => {
        setTotal(total);
        setData(newData);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  };

  const handlePaginationStateChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    if (page >= 0) setPage(page);
    if (pageSize >= 1) setLimit(pageSize);
  };

  const handlerSortModelChange = (newModel: GridSortModel) => {
    if (newModel?.length === 0) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    const { field, sort } = newModel[0];

    if (!field) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    setField(field as keyof NurseResponse);
    setOrder(sort === 'asc' ? 'ASC' : 'DESC');
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, field, order]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 300,
      valueGetter: ({ row: { user } }) => {
        return user.name;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      valueGetter: ({ row: { user } }) => {
        return user.email;
      },
    },
    {
      field: 'active',
      headerName: 'Ativo',
      width: 200,
      valueGetter: ({ row: { user } }) => {
        return user.enable;
      },
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 200,
      valueGetter: ({ row: { user } }) => {
        return user.phone;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      getActions: ({ row: { user, id } }: GridRowParams<NurseResponse>) => [
        <GridActionsCellItem
          key={user.enable === 'no' ? `${id}-restore` : `${id}-delete`}
          icon={user.enable === 'no' ? <RestoreIcon /> : <DeleteIcon />}
          onClick={() =>
            user.enable === 'no'
              ? handleRestoreDialog(String(id))
              : handleDeleteDialog(String(id))
          }
          label='Delete'
        />,
        <GridActionsCellItem
          key={`${id}-update`}
          icon={<UpdateIcon />}
          onClick={() => {
            handleNavigateToForm(String(id));
          }}
          label='Update'
        />,
      ],
    },
  ];

  return (
    <NursesUI
      {...{
        rows: data,
        columns,
        isLoading,
        handleNavigateToForm,
        handlePaginationStateChange,
        handlerSortModelChange,
        paginationState: { page, pageSize: limit },
        totalRows: total,
      }}
    />
  );
};

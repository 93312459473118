import { Control, Controller, FieldPath } from 'react-hook-form';
import { CampaignFormInputs } from '../interfaces/CampaignFormInputs';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  InputAdornment,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FormControl } from '@mui/base';
import SearchIcon from '@mui/icons-material/Search';
import { DoctorSearchResponse } from 'hooks/api/Doctors/interfaces/doctorSearch.response';
import { DoctorResponse } from 'hooks/api/Doctors/interfaces/doctor.response';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export const DoctorModal = ({
  control,
  visible,
  handleClose,
  searchTerm,
  setSearchTerm,
  doctors,
  selectedPractice,
}: {
  control: Control<CampaignFormInputs>;
  visible: boolean;
  handleClose: () => void;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  doctors: DoctorSearchResponse[];
  selectedPractice: string | undefined;
}) => {
  const theme = useTheme();

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={style}>
        <FormControl>
          <OutlinedInput
            id='input-searchDoctors'
            color='secondary'
            fullWidth
            autoComplete='on'
            type={'text'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <div style={{ maxHeight: '60%' }}>
          <Controller
            name={'practices'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup>
                {doctors.map((doctor) => (
                  <FormControlLabel
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    onChange={() => {
                      const practices = value.map((item) => {
                        if (item.name === selectedPractice) {
                          return {
                            name: item.name,
                            doctorId: doctor.id,
                            specialtyId: item.specialtyId,
                            hours: item.hours,
                          };
                        } else {
                          return item;
                        }
                      });
                      onChange(practices);
                    }}
                    control={<Radio />}
                    checked={
                      value.find((item) => item.name === selectedPractice)
                        ?.doctorId === doctor.id
                    }
                    label={`${doctor.name} - ${doctor.specialties
                      .map((specialty: any) => specialty.name)
                      .join(doctor.specialties.length > 1 ? ', ' : '')}`}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            id={'button-close-modal'}
            variant='contained'
            color='secondary'
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InputText } from 'components/input';
import { LoadingButton } from 'components/loadingButton';

import { NumberInput } from 'components/numberInput';
import { LoadingPage } from '../../components/loadingPage';
import { useInputsStyle } from '../../hooks/inputsStyle';
import { CampaignFormInputs } from './interfaces/CampaignFormInputs';
import { Dropdown } from 'components/select';
import { CustomDatePicker } from 'components/datePicker';
import { DoctorModal } from './components/doctorModal';
import { Dispatch, SetStateAction } from 'react';
import { DoctorSearchResponse } from 'hooks/api/Doctors/interfaces/doctorSearch.response';
import { PaginatedTable } from 'components/paginatedTable';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { NurseSearchEntity } from 'hooks/api/Nurse/interfaces/nurse.search.response';
import { NurseModal } from './components/nurseModal';
import { SpecialtyEntityResponse } from 'hooks/api/Specialty/interfaces/specialtySearch.response';
import { PracticeModal } from './components/practiceModal';
import { HoursModal } from './components/hoursModal';

export const CampaignFormUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  isLoadingGetData,
  id,
  control,
  toggleVisibleDoctor,
  toggleVisibleNurse,
  toggleVisibleHours,
  toggleVisiblePractice,
  visibleDoctor,
  visibleNurse,
  visibleHours,
  visiblePractice,
  searchTermDoctor,
  setSearchTermDoctor,
  searchTermNurse,
  setSearchTermNurse,
  doctors,
  practiceColumns,
  practiceRows,
  nurseColumns,
  nurseRows,
  nurses,
  selectedPractice,
  specialties,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<CampaignFormInputs>;
  errors: FieldErrors<CampaignFormInputs>;
  isLoading: boolean;
  id: string | undefined;
  isLoadingGetData: boolean;
  control: Control<CampaignFormInputs>;
  visibleDoctor: boolean;
  visibleNurse: boolean;
  visiblePractice: boolean;
  visibleHours: boolean;
  toggleVisibleDoctor: () => void;
  toggleVisibleNurse: () => void;
  toggleVisiblePractice: () => void;
  toggleVisibleHours: () => void;
  searchTermDoctor: string;
  setSearchTermDoctor: Dispatch<SetStateAction<string>>;
  searchTermNurse: string;
  setSearchTermNurse: Dispatch<SetStateAction<string>>;
  doctors: DoctorSearchResponse[];
  practiceColumns: GridColDef[];
  practiceRows: {
    name: string;
    doctorId: number;
    specialtyId: number;
    hours: { date: string; startHour: string; endHour: string }[];
  }[];
  nurses: NurseSearchEntity[];
  nurseColumns: GridColDef[];
  nurseRows: NurseSearchEntity[];
  selectedPractice: string | undefined;
  specialties: SpecialtyEntityResponse[];
}) => {
  const theme = useTheme();

  const {
    container,
    inputsSections,
    normalInput,
    buttonSection,
    buttonSize,
    modalButton,
  } = useInputsStyle({ theme });

  return (
    <LoadingPage isLoading={isLoadingGetData}>
      <Grid {...container}>
        <Grid item xs={12}>
          <Grid item xs={12} mb={3}>
            <Typography variant='h4' component='h1' color='secondary'>
              {id === 'new' ? 'Criar Mutirão' : `Alterar Mutirão #${id}`}
            </Typography>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-title'
                  label='Titulo'
                  validationMessage={errors.title?.message}
                  register={register('title')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                <CustomDatePicker
                  id='input-start'
                  label='Data de início'
                  name='start'
                  disabled={isLoading}
                  control={control}
                  validationMessage={errors.start?.message}
                />
              </Grid>
              <Grid {...normalInput}>
                <CustomDatePicker
                  id='input-end'
                  label='Data de Término'
                  name='end'
                  disabled={isLoading}
                  control={control}
                  validationMessage={errors.end?.message}
                />
              </Grid>
            </Grid>

            <Grid {...inputsSections}>
              <Grid {...modalButton}>
                <FormControl fullWidth error={!!errors.practices?.message}>
                  <Button
                    id={id}
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled={isLoading}
                    onClick={toggleVisiblePractice}
                  >
                    {'Criar consultório'}
                  </Button>
                  {errors.practices && (
                    <FormHelperText>{errors.practices.message}</FormHelperText>
                  )}
                </FormControl>
                <Typography
                  variant='h5'
                  component='h5'
                  color='primary'
                  sx={{ marginY: 1 }}
                >
                  Consultórios
                </Typography>
                <DataGrid
                  rows={practiceRows}
                  columns={practiceColumns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  getRowId={({ name }) => name}
                  pageSizeOptions={[10, 30]}
                  getRowHeight={() => 'auto'}
                />
              </Grid>
              <Grid {...modalButton}>
                <FormControl fullWidth error={!!errors.nurses?.message}>
                  <Button
                    id={id}
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled={isLoading}
                    onClick={toggleVisibleNurse}
                  >
                    {'Selecionar Enfermeiras'}
                  </Button>
                  {errors.nurses && (
                    <FormHelperText>{errors.nurses.message}</FormHelperText>
                  )}
                </FormControl>
                <Typography
                  variant='h5'
                  component='h5'
                  color='primary'
                  sx={{ marginY: 1 }}
                >
                  Enfermeiras selecionadas
                </Typography>
                <DataGrid
                  rows={nurseRows}
                  columns={nurseColumns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[10, 30]}
                />
              </Grid>
            </Grid>

            <DoctorModal
              {...{
                name: 'doctors',
                control,
                visible: visibleDoctor,
                handleClose: toggleVisibleDoctor,
                searchTerm: searchTermDoctor,
                setSearchTerm: setSearchTermDoctor,
                doctors,
                selectedPractice,
              }}
            />

            <PracticeModal
              {...{
                name: 'doctors',
                control,
                visible: visiblePractice,
                specialties,
                handleClose: toggleVisiblePractice,
              }}
            />

            <NurseModal
              {...{
                name: 'nurses',
                control,
                visible: visibleNurse,
                handleClose: toggleVisibleNurse,
                searchTerm: searchTermNurse,
                setSearchTerm: setSearchTermNurse,
                nurses,
              }}
            />

            <HoursModal
              {...{
                control,
                visible: visibleHours,
                handleClose: toggleVisibleHours,
                selectedPractice,
              }}
            />

            <Grid {...buttonSection}>
              <Grid {...buttonSize}>
                <LoadingButton
                  id='button-submit'
                  label={id != 'new' ? 'Alterar' : 'Cadastrar'}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </LoadingPage>
  );
};

export enum ApplicationRoutes {
  PARTNERS_FORM = '/partners/:id',
  HOME = '/home',
  SIGN_IN = '/',
  PLANS = '/plans',
  PLANS_FORM = '/plans/:id',
  NURSES = '/nurses',
  NURSES_FORM = '/nurses/:id',
  CAMPAIGN = '/campaign',
  CAMPAIGN_FORM = '/campaign/:id',
  USERS = '/users',
  USERS_FORM = '/users/:id',
  DOCTORS = '/doctors',
  PARTNERS = '/partners',
  RECOVER_PASSWORD = '/recover-password/:token',
  APPOINTMENT_REPORT = '/report/:id',
}

import { Button, Grid, Typography, useTheme } from '@mui/material';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { InputText } from 'components/input';
import { LoadingButton } from 'components/loadingButton';
import { LoadingPage } from 'components/loadingPage';
import { Dropdown } from 'components/select';

import { OptionResponse } from 'hooks/api/options/interfaces/option.response';
import { useInputsStyle } from 'hooks/inputsStyle';

import { PartnerFormInputs } from './interfaces/PartnerFormInputs';
import { MaskedInput } from 'components/maskedInput';

import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '../../utils/navigation/applicationRoutes';

export const PartnersFormUI = ({
  onSubmit,
  register,
  errors,
  isLoading,
  id,
  control,
  stateOptions,
  cityOptions,
  isLoadingGetData,
  isGettingCity,
  isGettingState,
}: {
  onSubmit: (e?: React.BaseSyntheticEvent) => void;
  register: UseFormRegister<PartnerFormInputs>;
  errors: FieldErrors<PartnerFormInputs>;
  isLoading: boolean;
  id: string | undefined;
  control: Control<PartnerFormInputs>;
  stateOptions: OptionResponse[];
  cityOptions: OptionResponse[];
  isLoadingGetData: boolean;
  isGettingCity: boolean;
  isGettingState: boolean;
}) => {
  const theme = useTheme();
  const { container, inputsSections, normalInput, buttonSection, buttonSize } =
    useInputsStyle({ theme });

  const navigate = useNavigate();

  return (
    <LoadingPage isLoading={isLoadingGetData}>
      <Grid {...container}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant='h4' component='h1' color='secondary'>
              {id === 'new'
                ? 'Cadastro de Parceiros'
                : `Alterar Parceiro #${id}`}
            </Typography>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-name'
                  label='Nome'
                  validationMessage={errors.name?.message}
                  register={register('name')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                {/*<InputText
                  label='Telefone'
                  validationMessage={errors.phone?.message}
                  register={register('phone')}
                  disabled={isLoading}
                />*/}
                <MaskedInput
                  id='input-phone'
                  label='Telefone'
                  validationMessage={errors.phone?.message}
                  register={register('phone')}
                  disabled={isLoading}
                  mask={['(00) 0000-0000', '(00) 00000-0000']}
                  control={control}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-email'
                  label='Email'
                  validationMessage={errors.email?.message}
                  register={register('email')}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                {/*<InputText
                  label='CPF|CNPJ'
                  validationMessage={errors.tax_id?.message}
                  register={register('tax_id')}
                  disabled={isLoading}
                />*/}
                <MaskedInput
                  id='input-document'
                  label='CPF|CNPJ'
                  validationMessage={errors.tax_id?.message}
                  register={register('tax_id')}
                  disabled={isLoading}
                  mask={['000.000.000-00', '00.000.000/0000-00']}
                />
              </Grid>
              <Grid {...normalInput}>
                {/*<InputText
                  label='CEP'
                  validationMessage={errors.postal_code?.message}
                  register={register('postal_code')}
                  disabled={isLoading}
                />*/}
                <MaskedInput
                  id='input-cep'
                  label='CEP'
                  validationMessage={errors.postal_code?.message}
                  register={register('postal_code')}
                  disabled={isLoading}
                  mask={'00000-000'}
                />
              </Grid>
              <Grid {...normalInput}>
                <Dropdown<PartnerFormInputs>
                  id='dropdown-state'
                  control={control}
                  label='Estado'
                  name='state'
                  disabled={isLoading || isGettingState}
                  key='state'
                  validationMessage={errors.state?.message}
                  options={stateOptions}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <Dropdown<PartnerFormInputs>
                  id='dropdown-city'
                  control={control}
                  label='Cidade'
                  name='city'
                  disabled={isLoading || isGettingCity}
                  key='city'
                  validationMessage={errors.city?.message}
                  options={cityOptions}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-address'
                  label='Endereço'
                  validationMessage={errors.address?.message}
                  register={register('address')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-address-2'
                  label='Endereço linha 2'
                  validationMessage={errors.address2?.message}
                  register={register('address2')}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-neighborhood'
                  label='Bairro'
                  validationMessage={errors.neighborhood?.message}
                  register={register('neighborhood')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-number'
                  label='Número'
                  validationMessage={errors.address_number?.message}
                  register={register('address_number')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid {...normalInput}>
                <InputText
                  id='input-password'
                  label='Senha'
                  validationMessage={errors.password?.message}
                  register={register('password')}
                  disabled={isLoading}
                  type='password'
                />
              </Grid>
            </Grid>
            <Grid {...inputsSections}>
              <Grid {...normalInput}>
                <InputText
                  id='input-confirm-password'
                  label='Confirmar senha'
                  validationMessage={errors.confirmPassword?.message}
                  register={register('confirmPassword')}
                  disabled={isLoading}
                  type='password'
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
              }}
            >
              <Button
                id='button-cancel'
                startIcon={<CancelIcon />}
                variant='outlined'
                fullWidth
                sx={{
                  marginRight: 10,
                }}
                onClick={() => navigate(ApplicationRoutes.SIGN_IN)}
              >
                Cancelar
              </Button>
              <LoadingButton
                id='button-submit'
                label={id != 'new' ? 'Alterar' : 'Cadastrar'}
                isLoading={isLoading}
              />
            </div>
          </form>
        </Grid>
      </Grid>
    </LoadingPage>
  );
};

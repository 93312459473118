import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'hooks/alert';
import { usePlanService } from 'hooks/api/Plans';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';
import { CampaignFormUI } from './campaignForm.ui';
import { makeRequestBody } from './utils/makeRequestBody';
import { schema } from './validations/validation';
import { CampaignFormInputs } from './interfaces/CampaignFormInputs';
import { campaignEmpty } from './utils/plan.empty';
import { useCampaignService } from 'hooks/api/Campaign';
import { DoctorSearchResponse } from 'hooks/api/Doctors/interfaces/doctorSearch.response';
import { useDoctorsService } from 'hooks/api/Doctors';
import dayjs from 'dayjs';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNurseService } from 'hooks/api/Nurse';
import { NurseSearchEntity } from 'hooks/api/Nurse/interfaces/nurse.search.response';
import { useSpecialtyService } from 'hooks/api/Specialty';
import { SpecialtyEntityResponse } from 'hooks/api/Specialty/interfaces/specialtySearch.response';
import { Button } from '@mui/material';

export const CampaignFormContainer = () => {
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);
  const [searchTermDoctor, setSearchTermDoctor] = useState<string>('');
  const [searchTermNurse, setSearchTermNurse] = useState<string>('');
  const [visibleDoctor, setVisibleDoctor] = useState<boolean>(false);
  const [visiblePractice, setVisiblePractice] = useState<boolean>(false);
  const [visibleHours, setVisibleHours] = useState<boolean>(false);
  const [visibleNurse, setVisibleNurse] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<DoctorSearchResponse[]>([]);
  const [nurses, setNurses] = useState<NurseSearchEntity[]>([]);
  const [selectedPractice, setSelectedPractice] = useState<string>();
  const [specialties, setSpecialties] = useState<SpecialtyEntityResponse[]>([]);
  const { postCampaign, putUpdateCampaign, getCampaign } = useCampaignService();
  const { searchDoctorsCampaign } = useDoctorsService();
  const { searchNurses } = useNurseService();
  const { getSearchSpecialty } = useSpecialtyService();
  const { id } = useParams();
  const navigate = useNavigate();
  const { success } = useAlert();
  const [options, setOptions] = useState([
    { value: '1', label: 'Mensal' },
    { value: '3', label: 'Trimestral' },
    { value: '6', label: 'Semestral' },
    { value: '12', label: 'Anual' },
  ]);

  useEffect(() => {
    getSearchSpecialty().then((result) => {
      setSpecialties(result.data);
    });
  }, []);

  const toggleVisibleDoctor = (name?: string) => {
    setSelectedPractice(name);
    setVisibleDoctor(!visibleDoctor);
  };

  const toggleVisiblePractice = () => {
    setVisiblePractice(!visiblePractice);
  };

  const toggleVisibleHours = (name?: string) => {
    setSelectedPractice(name);
    setVisibleHours(!visibleHours);
  };

  const toggleVisibleNurse = () => {
    setVisibleNurse(!visibleNurse);
  };

  const formMethods = useForm<CampaignFormInputs>({
    defaultValues: campaignEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    control,
    watch,
  } = formMethods;

  useEffect(() => {
    searchNurses(searchTermNurse).then((res) => {
      setNurses(res.data);
      searchDoctorsCampaign(searchTermDoctor).then((response) => {
        setDoctors(response.data);
        if (id === 'new') return;
        setIsLoadingGetData(true);
        getCampaign(Number(id))
          .then((result) => {
            setValue('title', result.title);
            setValue('end', result.end);
            setValue('start', result.start);
            if (result.practices.length > 0) {
              setValue(
                'practices',
                result.practices.map((practice) => {
                  return {
                    name: practice.name,
                    doctorId: practice.doctor.id,
                    hours: practice.hours.map((hour) => {
                      return {
                        date: dayjs(hour.date).format('YYYY-MM-DD'),
                        startHour: hour.startHour
                          .split(':')
                          .slice(0, 2)
                          .join(':'),
                        endHour: hour.endHour.split(':').slice(0, 2).join(':'),
                      };
                    }),
                    specialtyId: practice.specialty.id,
                  };
                })
              );
            }
            if (result.nurses.length > 0) {
              setValue(
                'nurses',
                result.nurses.map((nurse) => {
                  return {
                    ...nurse,
                    name: nurse.user.name,
                    email: nurse.user.email,
                    phone: nurse.user.phone,
                    idUser: nurse.user.id,
                  };
                })
              );
            }
          })
          .finally(() => setIsLoadingGetData(false));
      });
    });
  }, [id]);

  const onSubmit = handleSubmit(async (data: CampaignFormInputs) => {
    const body = makeRequestBody(data);
    let promise: Promise<any>;
    if (id === 'new') {
      promise = postCampaign({ body });
    } else {
      promise = putUpdateCampaign({ body }, Number(id));
    }
    await promise
      .then((res) => {
        success('Plano salvo com sucesso!');
        navigate(ApplicationRoutes.CAMPAIGN);
      })
      .catch((err) => console.log(err));
  });

  const handleRemovePractice = (name: string) => {
    let practices = watch('practices');
    practices = practices.filter((practice) => practice.name !== name);
    setValue('practices', [...practices]);
  };

  const handleRemoveNurse = (id: number) => {
    let nurses = watch('nurses');
    nurses = nurses.filter((nurse) => nurse.id !== id);
    setValue('nurses', [...nurses]);
  };

  useEffect(() => {
    searchDoctorsCampaign(searchTermDoctor).then((response) => {
      setDoctors(response.data);
    });
  }, [searchTermDoctor]);

  useEffect(() => {
    searchNurses(searchTermNurse).then((response) => {
      setNurses(response.data);
    });
  }, [searchTermNurse]);

  const separateByDate = (
    hours: { date: string; startHour: string; endHour: string }[]
  ) => {
    const formattedData: { [key: string]: string[] } = {};
    const sortedHours = hours.sort((a, b) => {
      // Compare dates
      const dateComparison = a.date.localeCompare(b.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }

      // If dates are equal, compare hours
      return a.startHour.localeCompare(b.startHour);
    });

    // Loop through the array
    sortedHours.forEach((item) => {
      // Extract date and name from the current object
      const { date, startHour, endHour } = item;

      // Check if the date is already in the formattedData object
      if (!formattedData[date]) {
        formattedData[date] = [`${startHour} - ${endHour}`];
      } else {
        formattedData[date].push(`${startHour} - ${endHour}`);
      }
    });
    return formattedData;
  };

  const practiceColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 170,
    },
    {
      field: 'specialtyId',
      width: 170,
      headerName: 'Especialidade',
      valueGetter: ({ row: { specialtyId } }) => {
        return specialties.find((specialty) => specialty.id === specialtyId)
          ?.name;
      },
    },
    {
      field: 'hours',
      width: 170,
      headerName: 'Horarios',
      renderCell: ({ row: { hours } }) => {
        const formattedData = separateByDate(hours);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {Object.entries(formattedData).map(([formattedDate, names]) => (
              <>
                <div style={{ fontWeight: 'bold' }}>{formattedDate}</div>
                {names.map((name) => (
                  <div>{name}</div>
                ))}
              </>
            ))}
          </div>
        );
      },
    },
    {
      field: 'doctorId',
      width: 170,
      headerName: 'Médico',
      valueGetter: ({ row: { doctorId } }) =>
        typeof doctors.find((doctor) => doctor.id === doctorId) !== undefined
          ? doctors.find((doctor) => doctor.id === doctorId)?.name
          : 'Não selecionado',
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 420,
      getActions: ({
        row: { id, name },
      }: GridRowParams<DoctorSearchResponse>) => [
        <Button
          id={`button-select-doctor-${id}`}
          variant='contained'
          color='secondary'
          onClick={() => toggleVisibleDoctor(name)}
          sx={{ maxHeight: 50 }}
        >
          Selecionar médico
        </Button>,
        <Button
          id={`button-add-hours-${id}`}
          variant='contained'
          color='secondary'
          onClick={() => toggleVisibleHours(name)}
          sx={{ maxHeight: 50 }}
        >
          Adicionar Horário
        </Button>,
        <GridActionsCellItem
          key={`${id}-delete`}
          icon={<DeleteIcon sx={{ color: '#ff3333' }} />}
          onClick={() => handleRemovePractice(name)}
          label='Delete'
        />,
      ],
    },
  ];

  const nurseColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 850,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      getActions: ({ row: { id } }: GridRowParams<NurseSearchEntity>) => [
        <GridActionsCellItem
          key={`${id}-delete`}
          icon={<DeleteIcon sx={{ color: '#ff3333' }} />}
          onClick={() => handleRemoveNurse(id)}
          label='Delete'
        />,
      ],
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <CampaignFormUI
        {...{
          onSubmit,
          register,
          isLoading,
          errors,
          id,
          isLoadingGetData,
          control,
          toggleVisibleDoctor,
          toggleVisibleNurse,
          toggleVisiblePractice,
          toggleVisibleHours,
          visibleDoctor,
          visibleNurse,
          visibleHours,
          visiblePractice,
          searchTermDoctor,
          setSearchTermDoctor,
          searchTermNurse,
          setSearchTermNurse,
          doctors,
          practiceColumns,
          practiceRows: watch('practices'),
          nurseColumns,
          nurseRows: watch('nurses'),
          nurses,
          selectedPractice,
          specialties,
        }}
      />
    </FormProvider>
  );
};

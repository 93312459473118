import { PartnerFormInputs } from '../interfaces/PartnerFormInputs';

export const partnerEmpty: PartnerFormInputs = {
  name: '',
  phone: '',
  email: '',
  tax_id: '',
  address: '',
  address2: '',
  address_number: '',
  city: '',
  state: '',
  neighborhood: '',
  postal_code: '',
  password: '',
  confirmPassword: '',
};

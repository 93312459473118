import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';

import UpdateIcon from '@mui/icons-material/Edit';

import { DoctorResponse } from 'hooks/api/Doctors/interfaces/doctor.response';
import { format } from 'utils/dateUtilts';

export const doctorTableColumns = ({
  handleDelete,
}: {
  handleDelete: (id: string) => void;
}): GridColDef<DoctorResponse>[] => {
  return [
    {
      field: 'user.id',
      headerName: 'ID',
      width: 100,
      valueGetter: ({
        row: {
          user: { id },
        },
      }) => id,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Aprovar?',
      sortable: false,
      getActions: ({ row }: GridRowParams<DoctorResponse>) => [
        <GridActionsCellItem
          key={`edit-${row.id}`}
          icon={<UpdateIcon />}
          onClick={() => handleDelete(String(row.id))}
          label='Delete'
        />,
      ],
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status Registro',
      valueGetter: ({ row: { status, receiverId } }) => {
        if (!receiverId) {
          return 'Contêm Pendencias';
        } else {
          const statusMap = {
            'waiting_approval': 'Aguardando Aprovação',
            'denied': 'Negado',
            'approved': 'Aprovado',
          };

          return statusMap[status];
        }
      },
    },
    {
      field: 'user.name',
      headerName: 'Nome',
      valueGetter: ({
        row: {
          user: { name },
        },
      }) => name,
    },
    {
      field: 'specialty',
      width: 200,
      headerName: 'Especialidade',
      valueGetter: ({ row: { specialties } }) =>
        specialties
          .map((specialty: any) => specialty.name)
          .join(specialties.length > 1 ? ', ' : ''),
    },
    {
      field: 'crm',
      width: 150,
      headerName: 'CRM',
      valueGetter: ({ row: { crm } }) => crm,
    },
    {
      field: 'user.email',
      headerName: 'Email',
      width: 200,
      valueGetter: ({
        row: {
          user: { email },
        },
      }) => email,
    },
    {
      field: 'user.phone',
      headerName: 'Telefone',
      width: 150,
      valueGetter: ({
        row: {
          user: { phone },
        },
      }) => phone,
    },
    {
      field: 'user.last_lgpd',
      headerName: 'Última LGPD',
      width: 150,
      valueGetter: ({
        row: {
          user: { last_lgpd },
        },
      }) => {
        return format(new Date(last_lgpd));
      },
    },
    {
      field: 'postalCode',
      headerName: 'CEP',
      width: 200,
      valueGetter: ({ row: { postalCode } }) => postalCode ?? '-',
    },
    {
      field: 'state',
      headerName: 'Estado',
      width: 200,
      valueGetter: ({ row: { state } }) => state,
    },
    {
      field: 'city',
      headerName: 'Cidade',
      width: 200,
      valueGetter: ({ row: { city } }) => city,
    },
    {
      field: 'neighborhood',
      headerName: 'Bairro',
      width: 200,
      valueGetter: ({ row: { neighborhood } }) => neighborhood,
    },
    {
      field: 'address',
      headerName: 'Endereço',
      width: 200,
      valueGetter: ({ row: { address } }) => address,
    },
    {
      field: 'user.deletedAt',
      headerName: 'Arquivado em',
      width: 200,
      valueGetter: ({
        row: {
          user: { deletedAt },
        },
      }) => {
        return deletedAt ? format(new Date(deletedAt)) : '';
      },
    },
    {
      field: 'enable',
      headerName: 'Ativo',
      sortable: false,
      width: 100,
      valueGetter: ({
        row: {
          user: { deletedAt },
        },
      }) => {
        return !deletedAt ? 'Sim' : 'Não';
      },
    },
  ];
};

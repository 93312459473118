import { useApi } from 'hooks/api';
import { useAuth } from 'hooks/auth';

import { LoginRequest } from './interfaces/login.request';
import { LoginResponse } from './interfaces/login.response';

export const useLoginService = () => {
  const { post, put } = useApi();
  const { signIn, getToken } = useAuth();

  const postLogin = async ({ body }: LoginRequest) => {
    const { data } = await post<LoginResponse>('/user/login', body);
    return data;
  };

  const putLoginWithToken = async (): Promise<any> => {
    let oldToken = getToken();
    return put<LoginResponse>('/token/refresh', { oldToken: oldToken })
      .then((response) => {
        const accessToken = response.data.accessToken;
        const user = response.data.user;
        if (accessToken) {
          return signIn(accessToken, user);
        } else {
          return Promise.reject({
            status: false,
            message: 'Token inválido',
          });
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  return {
    postLogin,
    putLoginWithToken,
  };
};

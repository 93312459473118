import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks/auth';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';

import { Layout } from 'components/layout';

export const ProtectedRoutes = ({ children }: { children: ReactElement }) => {
  const { isSigned } = useAuth();
  if (!isSigned) return <Navigate to={ApplicationRoutes.SIGN_IN} />;
  return <Layout>{children}</Layout>;
};

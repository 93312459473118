import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid';

export const PaginatedTable = ({
  rows,
  columns,
  paginationState,
  handlePaginationStateChange,
  pageSizeOptions = [3, 5, 10, 15, 20],
  handlerSortModelChange,
  isLoading = false,
  totalRows,
}: {
  rows: GridRowsProp;
  columns: GridColDef[];
  totalRows: number;
  paginationState: { page: number; pageSize: number };
  handlePaginationStateChange: (newModel: GridPaginationModel) => void;
  handlerSortModelChange: (newModel: GridSortModel) => void;
  pageSizeOptions?: number[];
  isLoading?: boolean;
}) => {
  return (
    <DataGrid
      sx={{ width: '100%' }}
      rows={rows}
      columns={columns}
      rowCount={totalRows}
      initialState={{
        pagination: {
          paginationModel: paginationState,
        },
      }}
      slotProps={{
        pagination: {
          labelRowsPerPage: 'Registros por página',
          labelDisplayedRows(paginationInfo) {
            return `${paginationInfo.from}-${paginationInfo.to} de ${paginationInfo.count}`;
          },
        },
      }}
      pageSizeOptions={pageSizeOptions}
      onPaginationModelChange={handlePaginationStateChange}
      onSortModelChange={handlerSortModelChange}
      loading={isLoading}
      rowSelectionModel={'none'}
      autoHeight
      localeText={{
        noRowsLabel: 'Nenhum registro encontrado',
        footerRowSelected: (count) => ``,
      }}
      paginationMode='server'
    />
  );
};

import { useEffect, useState } from 'react';
import './index.scss';
import { Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import AppointmentReport from 'components/Report';
import { AppointmentEntityResponse } from 'hooks/api/Appointment/interfaces/appointment.response';
import { useAppointmentService } from 'hooks/api/Appointment';

const Report = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointments, setAppointments] =
    useState<AppointmentEntityResponse[]>();
  const { id } = useParams();
  const { getAppointmentsByCampaign } = useAppointmentService();

  const loadItems = () => {
    setIsLoading(true);
    if (id) {
      getAppointmentsByCampaign(Number(id)).then((response) => {
        setAppointments(response);
        setIsLoading(false);
      });
    }
  };

  const printDoc = () => {
    window.print();
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <div style={{ width: '100%' }}>
        {appointments && !isLoading && (
          <div style={{ width: '100%' }}>
            <Button
              id='btn-print'
              onClick={printDoc}
              variant='contained'
              color='primary'
              /*disabled={
                !items?.some(
                  (item) =>
                    item.status === StatusItemBudget.CONFIRMED ||
                    item.status === StatusItemBudget.SENT
                )
              }*/
            >
              Imprimir
            </Button>
            <div className='printable'>
              <AppointmentReport appointments={appointments} />
            </div>
          </div>
        )}
        {!appointments && (
          <div>
            <Typography
              style={{ color: '#000' }}
              variant='h4'
              component='h1'
              color='secondary'
            >
              Consultas não encontradas para esse mutirão!
            </Typography>
          </div>
        )}
        {isLoading && <div>Aguarde enquanto o arquivo é gerado!</div>}
      </div>
    </>
  );
};

export default Report;

import { convertToNumberOrUndefined } from 'utils/stringUtils';
import { PlanFormInputs } from '../interfaces/PlanFormInputs';
import { PlanRequestBody } from 'hooks/api/Plans/interfaces/plan.request';

export const makeRequestBody = ({
  discount,
  name,
  value,
  frequency,
}: PlanFormInputs): PlanRequestBody => {
  return {
    name,
    value: convertToNumberOrUndefined(value),
    discount: convertToNumberOrUndefined(discount),
    frequency: convertToNumberOrUndefined(frequency),
  };
};

import yup from 'utils/yup';
import { transformStringToNumberOrUndefined } from 'utils/stringUtils';

export const schema = yup.object().shape({
  practices: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number(),
        doctorId: yup.number(),
        specialtyId: yup.number(),
        hours: yup.array(),
      })
    )
    .min(1)
    .required(),
  nurses: yup.array().min(1).required(),
  end: yup.string().required(),
  start: yup.string().required(),
  title: yup.string().required(),
});

import { UserRequestBody } from 'hooks/api/Users/interfaces/user.request';
import { UserFormInputs } from '../interfaces/UserFormInputs';
import { getOnlyNumbersFromString } from 'utils/stringUtils';

export const makeRequestBody = (obj: UserFormInputs): UserRequestBody => {
  return {
    name: obj.name,
    phone: getOnlyNumbersFromString(obj.phone),
    email: obj.email,
    type: obj.type,
    //TODO make a random password generator and send it to the user by email
  };
};

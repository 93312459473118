import { useEffect, useState } from 'react';

import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';

import { useAlert } from 'hooks/alert';
import { usePartnerService } from 'hooks/api/Partners';
import { PartnerResponse } from 'hooks/api/Partners/interfaces/partner.response';
import { useUserService } from 'hooks/api/Users';
import { useDialog } from 'hooks/dialog';
import { usePagination } from 'hooks/pagination';

import { partnerTableColumns } from './partners.columns';
import { PartnerUI } from './partners.ui';

export const PartnerContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { getPartners: getRequest } = usePartnerService();
  const { deleteUser: deleteRequest, restoreUser: restorRequest } =
    useUserService();
  const {
    data,
    page,
    limit,
    field,
    order,
    total,
    setField,
    setOrder,
    setData,
    setLimit,
    setPage,
    setTotal,
  } = usePagination<PartnerResponse>();
  const { handleOpenDialog, handleCloseDialog } = useDialog();
  const { success } = useAlert();

  const handleDelete = async (id: string) => {
    await deleteRequest(Number(id));
    success('O cadastro do parceiro foi rejeitado.');
    handleFetchData();
  };

  const handleRestore = async (id: string) => {
    await restorRequest(Number(id));
    success('O cadastro do parceiro foi aprovado!');
    handleFetchData();
  };

  const handleDeleteDialog = async (id: string) => {
    handleOpenDialog(
      'Atenção',
      'Deseja aprovar ou rejeitar o cadastro do parceiro?',
      [
        {
          label: 'Cancelar',
          action: handleCloseDialog,
        },
        {
          label: 'Rejeitar',
          isCancel: true,
          action: () => {
            handleDelete(id).then(() => {
              handleCloseDialog();
            });
          },
        },
        {
          label: 'Aprovar',
          isCancel: true,
          action: () => {
            handleRestore(id).then(() => {
              handleCloseDialog();
            });
          },
        },
      ]
    );
  };

  const handleFetchData = async () => {
    setIsLoading(true);
    getRequest({ page, limit, field, order })
      .then(({ data, total }: any) => {
        console.log(data);
        setData(data);
        setTotal(total);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTotal(0);
        setData([]);
        setIsLoading(false);
      });
  };

  const handlePaginationStateChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    if (page >= 0) {
      setPage(page);
    }
    setLimit(pageSize);
  };

  const handlerSortModelChange = (newModel: GridSortModel) => {
    if (newModel?.length === 0) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    const { field, sort } = newModel[0];

    if (!field) {
      setField(undefined);
      setOrder(undefined);
      return;
    }

    setField(field as keyof PartnerResponse);
    setOrder(sort === 'asc' ? 'ASC' : 'DESC');
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, field, order]);

  const columns: GridColDef<PartnerResponse>[] = partnerTableColumns({
    handleDelete: handleDeleteDialog,
  });

  return (
    <PartnerUI
      {...{
        rows: data,
        columns,
        isLoading,
        handlePaginationStateChange,
        handlerSortModelChange,
        paginationState: { page, pageSize: limit },
        totalRows: total,
      }}
    />
  );
};

import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import { usePartnerService } from 'hooks/api/Partners';
import { useOptionService } from 'hooks/api/options';
import { OptionResponse } from 'hooks/api/options/interfaces/option.response';

import { ApplicationRoutes } from 'utils/navigation/applicationRoutes';

import { PartnerFormInputs } from './interfaces/PartnerFormInputs';
import { PartnersFormUI } from './partnersForm.ui';
import { makeRequestBody } from './utils/makeRequestBody';
import { partnerEmpty } from './utils/partner.empty';
import { schema } from './validations/validation';
import { getOnlyNumbersFromString } from 'utils/stringUtils';

export const PartnersFormContainer = () => {
  const { postPartner, putUpdatePartner, getPartner } = usePartnerService();
  const { getCity, getAdress, getOptions } = useOptionService();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoadingGetData, setIsLoadingGetData] = useState(false);
  const [isGettingState, setIsGettingState] = useState(true);
  const [isGettingCity, setIsGettingCity] = useState(true);
  const [stateOptions, setStateOptions] = useState<OptionResponse[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionResponse[]>([]);
  const formMethods = useForm<PartnerFormInputs>({
    defaultValues: partnerEmpty,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting: isLoading, errors },
    setValue,
    control,
    watch,
  } = formMethods;

  useEffect(() => {
    getOptions().then((options) => {
      setStateOptions(options);
      setIsGettingState(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsGettingCity(true);
    if (
      watch('state') !== undefined &&
      watch('state') !== null &&
      watch('state') !== ''
    ) {
      getCity(watch('state')).then((options) => {
        setCityOptions(options);
        setIsGettingCity(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('state')]);

  const handleGetAdress = async (cep: string) => {
    const crudeCep = getOnlyNumbersFromString(cep);
    getAdress(crudeCep)
      .then((address) => {
        setValue('address', address.street);
        setValue('state', address.state);
        setValue('neighborhood', address.neighborhood);
        setValue('city', address.city);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (watch('postal_code').length === 9) {
      handleGetAdress(watch('postal_code'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('postal_code')]);

  useEffect(() => {
    if (id === 'new') return;
    setIsLoadingGetData(true);
    getPartner(Number(id))
      .then((result) => {
        setValue('name', result.name);
        setValue('phone', result.phone);
        setValue('email', result.email);
      })
      .finally(() => {
        setIsLoadingGetData(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = handleSubmit(async (data: PartnerFormInputs) => {
    const body = makeRequestBody(data);
    let promise: Promise<any>;
    if (id === 'new') {
      promise = postPartner({ body });
    } else {
      promise = putUpdatePartner({ body }, Number(id));
    }
    await promise
      .then((res) => {
        console.log(res)
        navigate(ApplicationRoutes.USERS);
      })
      .catch((err) => {
        alert("Houve um erro no cadastro. Entre em contato conosco por meio de um dos nossos canais.")
        console.error(err)
      });
  });

  return (
    <PartnersFormUI
      {...{
        onSubmit,
        register,
        isLoading,
        errors,
        id,
        control,
        isLoadingGetData,
        stateOptions,
        cityOptions,
        isGettingCity,
        isGettingState,
      }}
    />
  );
};

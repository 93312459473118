import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { AlertProvider } from './providers/alert';
import { AuthProvider } from './providers/auth';
import { DialogProvider } from './providers/dialog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

import { AppRoutes } from './routes/appRoutes';
import { theme } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <DialogProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </DialogProvider>
        </AlertProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </BrowserRouter>
);

// reportWebVitals(console.debug);

import { useApi } from 'hooks/api';

import { PaginatedRequest } from '../interfaces/paginated.request';
import { PaginatedResponse } from '../interfaces/paginated.response';
import { NurseRequest } from './interfaces/nurse.request';
import { NurseResponse } from './interfaces/nurse.response';
import { NurseSearchEntity } from './interfaces/nurse.search.response';

export const useNurseService = () => {
  const { post, get, del, patch } = useApi();

  const postNurse = async ({ body }: NurseRequest) => {
    return post('/nurse/create', body);
  };

  const getNurse = async (id: number) => {
    const { data } = await get<NurseResponse>('/nurse/find/' + id);
    return data;
  };

  const getSearchNurse = async (
    params: PaginatedRequest<NurseResponse>
  ): Promise<PaginatedResponse<NurseResponse>> => {
    const { data } = await get<PaginatedResponse<NurseResponse>>(
      '/nurse/find-all/',
      { params }
    );
    return data;
  };

  const searchNurses = async (
    searchTerm?: string
  ): Promise<PaginatedResponse<NurseSearchEntity>> => {
    const { data } = await get<PaginatedResponse<NurseSearchEntity>>(
      searchTerm ? '/nurse/search?value=' + searchTerm : '/nurse/search/'
    );
    return data;
  };

  const deleteNurse = async (id: string) => {
    del('/nurse/delete/' + id);
  };

  const restoreNurse = async (id: number): Promise<void> => {
    await patch(`/nurse/restore/${id}`);
  };

  return {
    postNurse,
    getNurse,
    getSearchNurse,
    deleteNurse,
    restoreNurse,
    searchNurses,
  };
};

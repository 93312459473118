import { useApi } from '..';
import {
  SpecialtyEntityResponse,
  SpecialtySearchResponse,
} from './interfaces/specialtySearch.response';

export const useSpecialtyService = () => {
  const { get } = useApi();

  const getSearchSpecialty = async () => {
    const { data } = await get<SpecialtySearchResponse>('/specialty/find-all');

    console.log('data', data);

    return data;
  };

  const getSpecialty = async (id: number) => {
    const { data } = await get<SpecialtyEntityResponse>(
      '/specialty/find-one/' + id
    );

    console.log('data', data);

    return data;
  };

  return {
    getSearchSpecialty,
    getSpecialty,
  };
};

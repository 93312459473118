import yup from 'utils/yup';

export const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  tax_id: yup.string().required().min(14).max(18),
  address: yup.string().required(),
  address2: yup.string().nullable(),
  address_number: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  neighborhood: yup.string().required(),
  postal_code: yup.string().required().length(9),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')])
    .required(),
});

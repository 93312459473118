import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
    oneOf: 'As senhas não coincidem',
  },
  string: {
    email: 'E-mail inválido',
    min: 'Deve conter no mínimo ${min} caracteres',
    max: 'Deve conter no máximo ${max} caracteres',
  },
  number: {
    min: 'O valor mínimo é de ${min}',
    max: 'O valor máximo é de ${max}',
    positive: 'O valor deve ser positivo',
  },
  array: {
    min: 'Selecione pelo menos ${min}',
    max: 'Ultrapassando o maximo de ${max}',
  },
});

export default yup;

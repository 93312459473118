import { FC } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  CSSObject,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  styled,
  useTheme,
} from '@mui/material';
import { AppDrawerProps } from './interfaces/AppDrawerProps';

export const AppDrawerUI: FC<AppDrawerProps> = ({
  open,
  closeDrawer,
  menus,
}) => {
  const theme = useTheme();

  const drawerWidth = 240;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  return (
    <Drawer anchor='left' open={open} variant='permanent' onClose={closeDrawer}>
      <DrawerHeader>
        <IconButton onClick={closeDrawer}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {menus.map(({ handleOnclick, icon, id, name }) => (
          //aling text and icon
          <ListItemButton
            id={`button-${name}`}
            sx={{}}
            key={id}
            onClick={handleOnclick}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0,
              }}
            >
              {name}
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align_button {
  margin-left: 1%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  #container {
    all: unset;
  }
  #menu {
    display: none;
  }
  body {
    visibility: hidden;
  }
  #btn-print {
    display: none;
  }
  .printable {
    visibility: initial;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/report/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AACE;EACE;IACE,4CAAA;IACA,oCAAA;EAEJ;EAAE;IACE,UAAA;EAEJ;EAAE;IACE,aAAA;EAEJ;EAAE;IACE,kBAAA;EAEJ;EAAE;IACE,aAAA;EAEJ;EAAE;IACE,mBAAA;EAEJ;AACF","sourcesContent":[".align_button {\n    margin-left: 1%;\n  }\n  @media print {\n    body {\n      -webkit-print-color-adjust: exact !important;\n      print-color-adjust: exact !important;\n    }\n    #container {\n      all: unset;\n    }\n    #menu {\n      display: none;\n    }\n    body {\n      visibility: hidden;\n    }\n    #btn-print {\n      display: none;\n    }\n    .printable {\n      visibility: initial;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  useTheme,
} from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { SelectProps } from './interfaces/SelectProps';

export const Dropdown = <TFieldValues extends FieldValues>({
  id,
  label,
  disabled = false,
  validationMessage = '',
  control,
  options,
  name,
}: SelectProps<TFieldValues>) => {
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth error={!!validationMessage}>
          <InputLabel color='secondary' id='demo-simple-select-label'>
            {label}
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id={id}
            value={value}
            label={label}
            onChange={onChange}
            disabled={disabled}
            color='secondary'
          >
            {options.map(({ value, label }) => (
              <MenuItem id={`${id}-${label}`} key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {validationMessage && (
            <FormHelperText>{validationMessage}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

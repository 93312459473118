import './index.scss';
import { AppointmentEntityResponse } from 'hooks/api/Appointment/interfaces/appointment.response';
import moment from 'moment';
import { Table } from 'react-bootstrap';

function AppointmentReport({
  appointments,
}: {
  appointments: AppointmentEntityResponse[];
}) {
  return (
    <div className='pdfBox'>
      <div className='print-outline'></div>
      <div className='page-one'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table striped bordered hover style={{ maxWidth: '97%' }}>
            <thead>
              <tr>
                <th>Médico</th>
                <th align='center'>Paciente</th>
                <th align='center'>Data/Hora inicio</th>
                <th align='center'>Data/Hora Fim</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((row) => (
                <tr>
                  <td>{row.doctor.user.name}</td>
                  <td>{row.patient.user.name}</td>
                  <td>
                    {moment(new Date(row.appointment_date)).format(
                      'DD/MM/yyyy'
                    ) +
                      ' ' +
                      row.hours[0].hours}
                  </td>
                  <td>
                    {row.deletedAt !== null
                      ? 'Cancelada'
                      : row.appointment_end_time !== null
                      ? moment(new Date(row.appointment_end_time)).format(
                          'DD/MM/yyyy hh:mm:ss'
                        )
                      : 'Agendada'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default AppointmentReport;

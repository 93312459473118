import yup from 'utils/yup';
import { transformStringToNumberOrUndefined } from 'utils/stringUtils';

export const schema = yup.object().shape({
  name: yup.string().min(3).max(100).required(),
  value: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .positive()
    .required(),
  discount: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .max(100)
    .min(1)
    .required(),
  frequency: yup
    .number()
    .transform(transformStringToNumberOrUndefined)
    .required(),
});

import { useApi } from '..';
import { PaginatedRequest } from '../interfaces/paginated.request';
import { PaginatedResponse } from '../interfaces/paginated.response';
import { AppointmentEntityResponse } from './interfaces/appointment.response';

export const useAppointmentService = () => {
  const { get } = useApi();

  const getAppointmentsByCampaign = async (
    id: number
  ): Promise<AppointmentEntityResponse[]> => {
    return get<AppointmentEntityResponse[]>(
      '/appointment/find-campaign/' + id
    ).then((response) => {
      return response.data;
    });
  };

  return {
    getAppointmentsByCampaign,
  };
};
